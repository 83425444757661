import { useState, useEffect } from "react";

/**
 * External imports
 */
import fileDownload from "js-file-download";
import { useLocation } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useUtils,
  useSearch,
  useActions,
  useReports,
  useSelector,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports types
 */
import { UseReportsProps } from "../useReports";
import { FilterModel } from "../../types";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GenerateReportsPDFBody,
  GenerateReportsPDFOnSuccess,
  GenerateReportsPDFParams,
  GenerateReportsExcelBody,
  GenerateReportsExcelOnSuccess,
  GenerateReportsExcelParams,
} from "../useApi";

export const useCollaboratorWorkOrderReport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userInitialized } = useSelector((state) => state.account);
  const { config } = useSelector((state) => state.reports);
  const { dispatchSnackbar, dispatchMessage, updateReportsConfig } =
    useActions();
  const { apiCalls } = useApi({ withCredentials: true });

  const { formatActiveFilters, createFilter, getFieldType, parseQueryValue } =
    useFilterModelsUtils();
  const { order, orderBy, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();
  const [initRequest, setInitRequest] = useState(false);

  const onRequestPdfError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("WorkOrderReportPdf"),
      description: t("GeneratePdfReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  const onRequestExcelError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("WorkOrderReportExcel"),
      description: t("GenerateExcelReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  const downloadPdfReport = async (includeServices?: boolean) => {
    dispatchSnackbar({
      title: t("WorkOrderReportPdf"),
      description: t("GenerateWorkOrderReportPdf"),
      variant: "loading",
    });

    const reqBody: GenerateReportsPDFBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    const params: GenerateReportsPDFParams = {
      type: "collaborator-work-order",
      format: "normal",
      interval: "detailed",
    };

    if (includeServices) {
      reqBody.with_services = true;
    }

    const onSuccess: GenerateReportsPDFOnSuccess = (data, response) => {
      const fileName = `work_order_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.pdf`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("WorkOrderReportPdf"),
        description: t("GenerateWorkOrderReportSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsPDF(
      params,
      reqBody,
      onSuccess,
      onRequestPdfError,
    );
  };

  const downloadExcelReport = async () => {
    dispatchSnackbar({
      title: t("WorkOrderReportExcel"),
      description: t("GenerateWorkOrderReportExcel"),
      variant: "loading",
    });

    const reqBody: GenerateReportsExcelBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    const params: GenerateReportsExcelParams = {
      type: "collaborator-work-order",
      format: "normal",
      interval: "detailed",
    };

    const onSuccess: GenerateReportsExcelOnSuccess = (data, response) => {
      const fileName = `work_order_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.xls`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("WorkOrderReportExcel"),
        description: t("GenerateWorkOrderReportExcelSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsExcel(
      params,
      reqBody,
      onSuccess,
      onRequestExcelError,
    );
  };

  const useReportsProps: UseReportsProps = {
    type: "collaborator-work-order",
    format: "normal",
    order,
    orderBy,
    onSuccess: () => {
      setInitRequest(true);
    },
  };

  const {
    loading,
    report,
    triggerSearch,
    setTriggerSearch,
    generateReport,
    setReport,
    setLoading,
    handleSearch,
    deleteFilter,
    resetFilters,
  } = useReports(useReportsProps);

  const { formatDate } = useUtils();
  const { getUserOrganization } = useUserUtils();
  const debounce = useDebounce();

  const [modelsInitialized, setModelsInitialized] = useState(false);

  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      generateReport(filters, {
        reportType: "collaborator-work-order",
        reportFormat: "normal",
      });
    }, 500);
  };

  const getDefaultDateRange = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() - 1;
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    return [formatDate(startDate), formatDate(endDate)];
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return (
            model.field === "organization_id" || model.field === "created_at"
          );
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    const organization = getUserOrganization();

    if (organization) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      const dateRangeFilterModel = createFilter({
        field: "created_at",
        selected: getDefaultDateRange(),
        type: "range",
      });

      models.push(organizationFilterModel);
      models.push(dateRangeFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  const getReportTimeInterval = () => {
    const dateFilter = activeFilters.find(
      (filter) => filter.field === "created_at",
    );

    const format = "yyyy.MM.dd";

    return {
      startDate: formatDate(
        dateFilter ? dateFilter.selected[0] : new Date(),
        format,
      ),
      endDate: formatDate(
        dateFilter ? dateFilter.selected[1] : new Date(),
        format,
      ),
    };
  };

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setReport(undefined);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
      } else {
        generateReport(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) initializeFilterModels();
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        generateReport(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  return {
    report,
    loading,
    activeFilters,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    formatActiveFilters,
    getReportTimeInterval,
    downloadPdfReport,
    downloadExcelReport,
  };
};
