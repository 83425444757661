/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteCollaboratorWorkOrderApiCall,
  DeleteCollaboratorWorkOrderResponse,
} from "./DeleteCollaboratorWorkOrder.types";

export const DeleteCollaboratorWorkOrder: DeleteCollaboratorWorkOrderApiCall =
  (api) => async (workOrderId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteCollaboratorWorkOrderResponse>(
        `/collaborator-work-orders/${workOrderId}`,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
