import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useEvents,
  useSearch,
  useActions,
  useSelector,
  useDebounce,
  useUserUtils,
  useSocketUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, Product, TableData } from "../../types";
import {
  SearchProductsBody,
  SearchProductsOnSuccess,
  RequestOnError,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const ProductsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  const history = useHistory();
  const location = useLocation();

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the products
   */
  const [products, setProducts] = useState<Product[]>([]);

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the total products
   */
  const [totalProducts, setTotalProducts] = useState(0);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("name");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("asc");

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Gets socket event utils
   */
  const { handleSocketEvents } = useSocketUtils();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the filter model utility hook
   */
  const {
    filtersToQueryString,
    getFieldType,
    parseQueryValue,
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
  } = useFilterModelsUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization } = useUserUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchProducts(filterModels, 1);
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);

    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchProducts(filters, 1);
    }, 500);
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: key === "is_service" ? false : parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return (
            model.field === "organization_id" || model.field === "is_service"
          );
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    const isServiceFilter = createFilter({
      field: "is_service",
      selected: false,
      type: "boolean",
    });

    const organization = getUserOrganization();

    if (organization) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    models.push(isServiceFilter);

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles searching for products
   */
  const searchProducts = async (filters: FilterModel[], page?: number) => {
    const _filters: FilterModel[] = cloneDeep(filters);

    const isServiceFilterModel = createFilter({
      field: "is_service",
      selected: false,
      type: "boolean",
    });

    if (!findIndex(_filters, { field: "is_service" })) {
      _filters.push(isServiceFilterModel);
    }

    /**
     * Defines the request body
     */
    const reqBody: SearchProductsBody = {
      models: _filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    const queryString = filtersToQueryString(reqBody.models);
    history.push(`?${queryString}`);

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: SearchProductsOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        setProducts(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalProducts(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchProducts(reqBody, onRequestSuccess, onRequestError);
  };

  /**
   * Handles updating the work order in the table
   */
  const updateProduct = (product: Product) => {
    const index = findIndex(products, { id: product.id });
    const clonedProducts = cloneDeep(products);
    clonedProducts[index] = product;

    setProducts(clonedProducts);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchProducts(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchProducts(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Updates the state based on socket event updates
   */
  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "general",
        event: "GeneralEvent",
        withAuth: true,
        callback: (payload) =>
          handleSocketEvents({
            payload,
            modelName: "Product",
            setState: setProducts,
          }),
      });
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    products,
    activeFilters,
    totalProducts,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    setProducts,
    updateProduct,
    handlePageChange,
    setTotalProducts,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
