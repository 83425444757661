import { range } from "lodash";
import { addDays, format, getWeek, startOfISOWeek } from "date-fns";
import type { GenericObject, GuestAppointment } from "../../../types";

export const getTodayDate = () => {
  let today = new Date();

  /** Handles the Sunday edge case */
  if (today.getDay() === 0) {
    today.setDate(today.getDate() + 1);
  }

  return today;
};

export const sortAppointmentsByTime = (appointments: GuestAppointment[]) => {
  return [...appointments].sort(
    (a, b) => new Date(a.from).getTime() - new Date(b.from).getTime(),
  );
};

export const combineOverlappingAppointments = (
  appointments: GuestAppointment[],
) => {
  const sortedAppointments = sortAppointmentsByTime(appointments);
  const merged = [];

  for (let _appointment of sortedAppointments) {
    const appointment = { ..._appointment };
    const startDate = new Date(appointment.from);
    const endDate = new Date(appointment.to);

    const prevMerged = merged[merged.length - 1];

    const prevMergedEndDate = new Date(prevMerged?.to);
    const prevMergedStartDate = new Date(prevMerged?.from);

    if (merged.length > 0 && startDate <= prevMergedEndDate) {
      prevMerged.to = new Date(
        Math.max(prevMergedEndDate.getTime(), endDate.getTime()),
      ).toISOString();

      const durationStart = prevMergedStartDate.getTime();
      const durationEnd = prevMergedEndDate.getTime();

      prevMerged.duration = Math.round((durationEnd - durationStart) / 60000);
    } else {
      merged.push(appointment);
    }
  }

  return merged;
};

export const formatGuestAppointments = (appointments: GuestAppointment[]) => {
  return appointments.map((appointment) => ({
    isBreak: appointment.isBreak,
    startDate: new Date(appointment.from),
    endDate: new Date(appointment.to),
    id: appointment.id,
  }));
};

export const addBreaksToAppointments = (
  appointments: GuestAppointment[],
  breakInterval: string,
  weekNumber: number,
): GuestAppointment[] => {
  if (!breakInterval) return appointments;

  // Only proceed if breakInterval is in "HH:mm - HH:mm" format
  if (typeof breakInterval === "string" && breakInterval.includes("-")) {
    const startTime = breakInterval.split("-")[0].trim();
    const endTime = breakInterval.split("-")[1].trim();

    // Calculate the duration in minutes
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    const duration = endHour * 60 + endMinute - (startHour * 60 + startMinute);

    // Get the current year dynamically
    const currentYear = new Date().getFullYear();

    // Get the Monday of the specified ISO week number for the current year
    const firstDayOfWeek = startOfISOWeek(
      new Date(currentYear, 0, 1 + (weekNumber - 1) * 7),
    );

    // Create break appointments from Monday to Saturday
    const breakAppointments = range(0, 6).map((dayIndex) => {
      const currentDay = addDays(firstDayOfWeek, dayIndex);
      const formattedDate = format(currentDay, "yyyy-MM-dd");

      // Construct the 'from' and 'to' times
      const from = new Date(`${formattedDate}T${startTime}:00`);
      const to = new Date(`${formattedDate}T${endTime}:00`);

      return {
        appointmentGroupId: -1, // Breaks will have appointmentGroupId of -1
        duration,
        from: from.toISOString(),
        to: to.toISOString(),
        order: dayIndex + 1,
        isBreak: true,
        id: dayIndex + 1,
      };
    });

    // Return the original appointments combined with the newly created break appointments
    return [...appointments, ...breakAppointments];
  }

  return appointments;
};

export const handleExcludedDays = (
  weekNumber: number,
  publicSettings: GenericObject,
): number[] => {
  const { exclude, excludeSaturdays } = publicSettings;

  if (!exclude || exclude.length < 1) {
    let excludedDays = [0];

    if (excludeSaturdays) {
      excludedDays.push(6);
    }

    return excludedDays;
  }

  const result: any = {};

  exclude.forEach((excluded: number) => {
    const date = new Date(excluded);
    const year = date.getFullYear();
    const week = getWeek(date);
    const day = date.getDay();

    if (!result[year]) {
      result[year] = {};
    }

    if (!result[year][week]) {
      result[year][week] = [];
    }

    result[year][week].push(day);
  });

  const today = new Date();
  const todayYear = today.getFullYear();

  let excludedDays = result[todayYear]?.[weekNumber]
    ? [0, ...result[todayYear][weekNumber]]
    : [0];

  if (excludeSaturdays) {
    excludedDays.push(6);
  }

  return excludedDays;
};
