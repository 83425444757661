/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  DeleteCollaboratorApiCall,
  DeleteCollaboratorResponse,
} from "./DeleteCollaborator.types";

export const DeleteCollaborator: DeleteCollaboratorApiCall =
  (api) => async (collaboratorId, onSuccess, onError) => {
    try {
      const response = await api.delete<DeleteCollaboratorResponse>(
        `collaborators/${collaboratorId}`,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
