import type { Dispatch } from "@reduxjs/toolkit";
import type { SocketEventPayload } from "../../../services";

import { publicAppointmentsActions } from "../../../features";

export const guestEventHandler = (
  payload: SocketEventPayload<any>,
  dispatch: Dispatch,
) => {
  switch (payload.modelName) {
    case "Appointments":
      dispatch(publicAppointmentsActions.updateAppointmentsLive(payload));
      break;
    default:
      break;
  }
};
