/**
 * Imports hooks
 */
import {
  useSelector,
  useActions,
  useUserUtils,
  useStatistics,
  useWorkOrderUtils,
  useFilterModelsUtils,
} from "../../hooks";

/**
 * Imports APIs
 */
import {
  useLazySearchCollaboratorsQuery,
  useLazySearchClientsQuery,
  useLazyFetchAccountInformationQuery,
} from "../../redux";

/**
 * Imports types
 */
import {
  AxiosApiError,
  SearchClientsRequest,
  SearchCollaboratorsRequest,
} from "../../redux";
import { Account, FilterModel, WorkOrderType } from "../../types";

/**
 * Imports constants
 */
import { MAX_PAGE_SIZE } from "../../constants";

/**
 * Provides state management for the ProtectedRoutes component
 */
export const useProtectedRoutes = () => {
  /**
   * Gets the redux actions
   */
  const { dispatchMessage, updateDefaultWorkOrderType } = useActions();

  /**
   * Handles getting the account information
   */
  const [getAccountInformation] = useLazyFetchAccountInformationQuery();

  /**
   * Handles getting the clients
   */
  const [searchClientsQuery] = useLazySearchClientsQuery();

  const [searchCollaboratorsQuery] = useLazySearchCollaboratorsQuery();

  /**
   * Gets work order utils
   */
  const { getWorkOrderTypeByName } = useWorkOrderUtils();

  const { defaultWorkOrderType } = useSelector((state) => state.workOrder);

  const { requestStatistics } = useStatistics();

  /**
   * Gets user data utility functions
   */
  const { getUserOrganization } = useUserUtils();

  /**
   * Gets the filter model utility hook
   */
  const { createFilter } = useFilterModelsUtils();

  /**
   * Handle supdating the user settings that are stored in local storage
   */
  const updateUserLocalStorageSettings = (workOrderTypes: WorkOrderType[]) => {
    if (!defaultWorkOrderType) {
      const workOrderType = getWorkOrderTypeByName(
        "Vulcanizare",
        workOrderTypes,
      );

      if (!workOrderType) {
        updateDefaultWorkOrderType(workOrderTypes[0].id);
      } else {
        updateDefaultWorkOrderType(workOrderType.id);
      }
    }
  };

  /**
   * Handles updating the work order types
   */
  const updateWorkOrderTypes = (account: Account) => {
    const { workOrderTypes } = account;

    if (workOrderTypes.length > 0) {
      updateUserLocalStorageSettings(workOrderTypes);
    }
  };

  /**
   * Handles getting the users data
   */
  const getUserData = async () => {
    try {
      const response = await getAccountInformation();

      if (response.isSuccess) {
        const {
          data: { statistics, account },
        } = response.data;

        updateWorkOrderTypes(account);
        requestStatistics(statistics);
      }
    } catch (err) {
      const error = err as AxiosApiError;
      if (error.data && error.data.errorMessage) {
        const { errorMessage } = error.data;

        dispatchMessage({
          message: errorMessage,
          severity: "error",
        });
      }
    }
  };

  const getCollaborators = async () => {
    const models: FilterModel[] = [];

    const organization = getUserOrganization();

    if (organization) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);

      const requestBody: SearchCollaboratorsRequest = {
        models,
        order_by: "name",
        order_dir: "asc",
        page_size: MAX_PAGE_SIZE,
        page_count: 1,
      };

      try {
        await searchCollaboratorsQuery(requestBody);
      } catch (err) {
        const error = err as AxiosApiError;
        if (error.data && error.data.errorMessage) {
          const { errorMessage } = error.data;

          dispatchMessage({
            message: errorMessage,
            severity: "error",
          });
        }
      }
    }
  };

  /**
   * Handles getting the company clients
   */
  const getClients = async () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    if (organization) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);

      /**
       * Defines the request body
       */
      const requestBody: SearchClientsRequest = {
        models,
        order_by: "name",
        order_dir: "asc",
        page_size: MAX_PAGE_SIZE,
        page_count: 1,
      };

      try {
        await searchClientsQuery(requestBody);
      } catch (err) {
        const error = err as AxiosApiError;
        if (error.data && error.data.errorMessage) {
          const { errorMessage } = error.data;

          dispatchMessage({
            message: errorMessage,
            severity: "error",
          });
        }
      }
    }
  };

  return {
    getClients,
    getUserData,
    updateWorkOrderTypes,
    getCollaborators,
  };
};
