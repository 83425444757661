import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { t } from "i18next";

/**
 * Imports types
 */
import type { RootState } from "../../store";
import type { AxiosResponseError, AxiosBaseQuery } from "./axiosQuery.types";

/**
 * Imports utils
 */
import {
  getBaseUrl,
  fromSnakeToCamel,
  fromCamelToSnake,
  buildErrorObject,
} from "./axiosQuery.utils";
import { logoutUser } from "../../features/auth/auth.api";

/**
 * Defines the Axios instance
 */
const axios = Axios.create({
  baseURL: getBaseUrl(),
  timeout: 1000 * 35,
  timeoutErrorMessage: "Your request timed out",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Api-Version": "v1",
  },
});

/**
 * Creates the axios query service
 */
export const axiosQuery: AxiosBaseQuery = async (
  { url, guest, method, data, params, headers },
  queryApi,
) => {
  const { getState, dispatch } = queryApi;

  /**
   * Handles injecting the authorization headers into the api client
   */
  const injectAuthorizationHeaders = (): AxiosRequestConfig["headers"] => {
    const token = (getState() as RootState).auth.accessToken;

    if (token !== undefined && token.length > 0) {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
  };

  const injectGuestHeaders = (): AxiosRequestConfig["headers"] => {
    if (guest) {
      return {
        "Guest-Token": process.env.REACT_APP_GUEST_TOKEN || "",
      };
    }
  };

  /**
   * Handles preparing the request data before it's sent
   */
  const prepareData = <T>(data?: T) => {
    return data
      ? (fromCamelToSnake(data) as InternalAxiosRequestConfig<T>)
      : undefined;
  };

  try {
    const response = await axios({
      url,
      method,
      params,
      headers: {
        ...headers,
        ...injectAuthorizationHeaders(),
        ...injectGuestHeaders(),
      },
      data: prepareData(data),
      signal: queryApi.signal,
    });

    if (response.headers) {
      const { headers } = response;

      if (headers["content-type"] === "application/vnd.ms-excel") {
        return response as AxiosResponse<unknown, unknown>;
      }

      if (headers["content-type"] === "application/pdf") {
        return response as AxiosResponse<unknown, unknown>;
      }
    }

    /**
     * Normalizes response data from the api
     */
    const responseData = fromSnakeToCamel(response.data);

    return {
      data: responseData,
    };
  } catch (err: unknown) {
    const error = err as AxiosError<AxiosResponseError>;

    if (error.response) {
      const { status, data } = error.response;

      if (status === 403) {
        return {
          error: buildErrorObject(status, t("AccessDenied403")),
        };
      }

      if (status === 401) {
        if (
          data.errorMessage &&
          typeof data.errorMessage === "object" &&
          data.errorMessage?.pin
        ) {
          const { pin } = data.errorMessage;

          if (pin === "Wrong pin!") {
            return {
              error: buildErrorObject(status, "Wrong pin!"),
            };
          } else {
            dispatch(logoutUser.initiate());
          }
        }
      }

      if (status === 503) {
        return {
          error: buildErrorObject(status, t("TechnicalDifficultiesError")),
        };
      }
    }

    return {
      error: {
        status: error.response?.status,
        data: {
          errorMessage: error.response?.data.errorMessage,
        },
      },
    };
  }
};
