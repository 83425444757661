/**
 * Imports helpers
 */
import { fromCamelToSnake, catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  CreateCollaboratorWorkOrderApiCall,
  CreateCollaboratorWorkOrderResponse,
} from "./CreateCollaboratorWorkOrder.types";

/**
 * Handles making the api call
 */
export const CreateCollaboratorWorkOrder: CreateCollaboratorWorkOrderApiCall =
  (api) => async (body, onSuccess, onError) => {
    try {
      const response = await api.post<CreateCollaboratorWorkOrderResponse>(
        "/collaborator-work-orders",
        fromCamelToSnake(body),
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
