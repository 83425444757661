import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep, snakeCase } from "lodash";
import fileDownload from "js-file-download";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useUtils,
  useSearch,
  useActions,
  useDebounce,
  useSelector,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { CollaboratorWorkOrder } from "../../types";
import { FilterModel, WorkOrderCompact, TableData } from "../../types";
import {
  RequestOnError,
  SearchWorkOrdersBody,
  ExportWorkOrdersBody,
  ExportWorkOrdersOnSuccess,
  SearchCollaboratorWorkOrdersOnSuccess,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CollaboratorWorkOrdersProvider: React.FC = (props) => {
  const { children } = props;
  const { Provider } = context;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [orderBy, setOrderBy] = useState("created_at");
  const [totalWorkOrders, setTotalWorkOrders] = useState(0);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [tableRowsLoading, setTableRowsLoading] = useState(false);
  const [workOrders, setWorkOrders] = useState<CollaboratorWorkOrder[]>([]);
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("desc");
  const [modelsInitialized, setModelsInitialized] = useState(false);
  const { userInitialized, accountInformation } = useSelector(
    (state) => state.account,
  );

  const debounce = useDebounce();
  const { apiCalls } = useApi({ withCredentials: true });
  const { dispatchSnackbar, dispatchMessage } = useActions();
  const { formatDate } = useUtils();

  const {
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
    getFieldType,
    parseQueryValue,
    filtersToQueryString,
  } = useFilterModelsUtils();

  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  const handleSearch = (searchValue: string) => {
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchWorkOrders(filterModels, 1);
    setActiveFilters(filterModels);
  };

  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchWorkOrders(filters, 1);
    }, 500);
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return model.field === "organization_id";
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    const organization = getUserOrganization();
    const workOrderType = getDefaultWorkOrderType();

    if (organization && workOrderType) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  const searchWorkOrders = async (filters: FilterModel[], page?: number) => {
    const reqBody: SearchWorkOrdersBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    const queryString = filtersToQueryString(reqBody.models);
    history.push(`?${queryString}`);

    const getCarData = (workOrder: CollaboratorWorkOrder) => {
      const make = workOrder.carMake ?? '';
      const model = workOrder.carModel ?? '';
      const year = workOrder.carYear ?? '';

      return `${make} ${model} ${year}`;
    }

    const onRequestSuccess: SearchCollaboratorWorkOrdersOnSuccess = (
      response,
    ) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        setWorkOrders(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalWorkOrders(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchCollaboratorWorkOrders(
      reqBody,
      onRequestSuccess,
      onRequestError,
    );
  };

  const onExportError: RequestOnError = () => {
    dispatchSnackbar({
      title: t("WorkOrdersExcelExport"),
      description: t("ExportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  const updateWorkOrder = (workOrder: WorkOrderCompact) => {
    // const index = findIndex(workOrders, { id: workOrder.id });
    // const clonedWorkOrders = cloneDeep(workOrders);
    // clonedWorkOrders[index] = workOrder;
    // setWorkOrders(clonedWorkOrders);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    let sneakProperty = snakeCase(property as string);
    sneakProperty = sneakProperty === 'car_data' ? 'car_make' : sneakProperty;
    const isAsc = orderBy === sneakProperty && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(sneakProperty);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchWorkOrders(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);

      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchWorkOrders(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    workOrders,
    activeFilters,
    totalWorkOrders,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    setWorkOrders,
    updateWorkOrder,
    handlePageChange,
    setTotalWorkOrders,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
