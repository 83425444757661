import { useMemo } from "react";

/**
 * External imports
 */
import {
  toSafeInteger,
  upperFirst,
  cloneDeep,
  toString,
  findIndex,
} from "lodash";

/**
 * Imports components
 */
import { Eye } from "react-feather";
import { Edit } from "react-feather";
import { BadgeColor } from "../../components/Badge";
import { BadgeOption } from "../../components/InputSelectBadge";

/**
 * Imports hooks
 */
import {
  useVat,
  useUtils,
  useSelector,
  useFormUtils,
  useUserUtils,
  useNavigation,
  useTimerUtils,
  useSearchUtils,
  useTranslation,
  useAccountSettings,
  useFilterModelsUtils,
} from "../../hooks";

/**
 * Imports types
 */
import {
  Tab,
  Event,
  WorkOrder,
  ListenType,
  ProductForm,
  WorkOrderSlim,
  BreadcrumbPath,
  CustomerOrder,
  WorkOrderCompact,
  CollaboratorWorkOrder,
} from "../../types";
import {
  CreateWorkOrderBody,
  CreateWorkOrderDraftBody,
  UpdateWorkOrderRelationsBody,
} from "../useApi";
import { UseTabsProps } from "../index";
import { FormState } from "react-hook-form";
import { FormBody } from "../useEditWorkOrder/Context";
import { InputChoiceProps } from "../../components/InputChoice";
import { WorkOrderGroup, ValidateTabProps } from "./useWorkOrderUtils.types";
import { AppointmentModel } from "@devexpress/dx-react-scheduler-material-ui";

/**
 * Imports constants
 */
import {
  ITP_WORK_ORDER_TYPE_VALUE,
  WORK_ORDER_TABS,
  WORK_ORDER_ITP_TABS,
  WORK_ORDER_COLLABORATOR_TABS,
} from "../../constants";

/**
 * Provides utility functions for manipulating work orders
 */
export const useWorkOrderUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  const { calculateVat } = useVat();
  const { localSearch } = useSearchUtils();
  const { modelsToInputs } = useFilterModelsUtils();

  /**
   * Gets the account state
   */
  const { workOrderTypes } = useSelector((state) => state.account);
  const { liveWorkOrders, suspendedWorkOrders } = useSelector(
    (state) => state.workOverview,
  );

  /**
   * Gets the paths
   */
  const { Paths } = useNavigation();

  /**
   * Gets the account settings
   */
  const {
    woPdfTyreTorqueNewLine,
    woRemoveDiscount,
    withVat,
    vatPercentage,
    ownProductDiscount,
  } = useAccountSettings();

  /**
   * Gets the user data utils
   */
  const {
    getProductById,
    getCarTypeName,
    getWheelTypeName,
    getPaymentTypeById,
    getCarTypeByName,
    getWheelTypeByName,
    getWorkOrderTypeById,
    getDefaultWorkOrderType,
    getDefaultWorkOrderTypeITP,
    getWorkOrderTypeByName,
    applyAccountDiscount,
  } = useUserUtils();

  /**
   * Gets form utils
   */
  const { getDefaultForm } = useFormUtils();

  /**
   * Gets timer utils
   */
  const { getTimeDifference, formatTimeUnit } = useTimerUtils();

  /**
   * Gets general utils
   */
  const {
    toFloat,
    toInteger,
    toCurrency,
    formatDate,
    removeEmpty,
    fromCamelToSnake,
    formatNumberSeparator,
  } = useUtils();

  /**
   * Returns work order types to be used in select inputs
   */
  const getWorkOrderTypeOptions = useMemo(() => {
    if (workOrderTypes.length < 1) return [];

    return workOrderTypes.map((workOrderType) => {
      return {
        label: workOrderType.name,
        value: workOrderType.id,
      };
    });
  }, [workOrderTypes]);

  /**
   * Returns an array of status strings from the provided work orders
   */
  const getWorkOrderStatuses = [
    {
      label: "New",
      value: "new",
      workOrders: [],
      color: "primary",
    },
    {
      label: "InProgress",
      value: "in-progress",
      workOrders: [],
      color: "info",
    },
    {
      label: "Scheduled",
      value: "on-hold",
      workOrders: [],
      color: "scheduled",
    },
    // {
    //   label: "Closed",
    //   value: "closed",
    //   workOrders: [],
    //   color: "error"
    // },
    {
      label: "Done",
      value: "done",
      workOrders: [],
      color: "success",
    },
  ];

  /**
   * Returns the translated status
   */
  const getStatusLabelByValue = (value: string) => {
    const statuses = getWorkOrderStatuses;
    const status = statuses.find((status) => status.value === value);

    return status ? t(status.label) : value;
  };

  /**
   * Group work orders by status
   */
  const groupByStatus = (workOrders: WorkOrder[]) => {
    const statuses = getWorkOrderStatuses;
    const groups: WorkOrderGroup[] = statuses.map((status) => {
      return {
        statusLabel: status.label,
        statusValue: status.value,
        workOrders: [],
      };
    });

    workOrders.forEach((workOrder) => {
      const { status } = workOrder;

      const index = findIndex(statuses, {
        value: status !== "upgrade-data" ? status : "on-hold",
      });

      if (index > -1) groups[index].workOrders.push(workOrder);
    });

    return groups;
  };

  /**
   * Formats the tyre dimensions
   */
  const formatTyreDimensions = (
    width?: string,
    height?: string,
    rim?: string,
  ) => {
    if (!height && !width && !rim) return "";

    const th = height || "-";
    const tw = width || "-";
    const tr = rim || "-";

    return `${tw}/${th} ${rim ? "R" + tr : tr}`;
  };

  /**
   * Gets the tyre dimensions from the work order
   */
  const getTyreDimensionsFromWorkOrder = (workOrder: WorkOrder) => {
    const { tyreService } = workOrder;

    if (tyreService) {
      const { tyreHeight, tyreWidth, tyreRim } = tyreService;

      return formatTyreDimensions(tyreWidth, tyreHeight, tyreRim);
    }
  };

  /**
   * Gets the car data from the work order
   */
  const getCarDataFromWorkOrder = (workOrder: WorkOrder) => {
    const { car, carService, workOrderTypeId } = workOrder;

    /**
     * Gets the work order type
     */
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType) {
      if (carService && workOrderType.name === "Service Auto") {
        const { make, model, modelType, year } = carService;

        if (!make || !model || !modelType || !year) return "-";

        return `${make || ""} ${model || ""} ${modelType || ""} ${year || ""}`;
      }

      if (car && workOrderType.shortName === "VLC") {
        const { make, model, manufacturingYear } = car;

        if (!make || !model || !manufacturingYear) return "-";

        return `${make || ""} ${model || ""} ${manufacturingYear || ""}`;
      }
    }
  };

  /**
   * Gets the payment type from the work order
   */
  const getPaymentTypeFromWorkOrder = (
    workOrder: WorkOrder | WorkOrderCompact,
  ) => {
    if (!workOrder) return undefined;
    const { paymentTypeId, paymentType } = workOrder;

    if (paymentType) return paymentType;

    const found = getPaymentTypeById(paymentTypeId);

    return found ? found : undefined;
  };

  /**
   * Gets the payment type id from the work order
   */
  const getPaymentTypeIdFromWorkOrder = (workOrder: WorkOrder) => {
    if (!workOrder) return 0;
    const { paymentTypeId, paymentType } = workOrder;

    if (paymentTypeId) return paymentTypeId;
    if (paymentType) return paymentType.id;
  };

  /**
   * Gets the delegate name from the work order
   */
  const getDelegateNameFromWorkOrder = (
    workOrder: WorkOrder | WorkOrderCompact,
  ) => {
    if (!workOrder) return "";
    const { delegateName, organizationClient } = workOrder;

    if (delegateName) return delegateName || "";
    if (organizationClient) return organizationClient.delegateName || "";

    return "";
  };

  /**
   * Returns the color of the work order status badge
   */
  const getWorkOrderStatusBadgeColor: (value: string) => BadgeColor = (
    value,
  ) => {
    switch (value) {
      case "new":
        return "secondary";
      case "in-progress":
        return "info";
      case "on-hold":
        return "scheduled";
      case "closed":
        return "error";
      case "done":
        return "success";
      default:
        return "default";
    }
  };

  /**
   * Returns the work order status options to be used in select inputs - type badge
   */
  const getWorkOrderStatusBadgeOptions = useMemo(() => {
    // const options = getWorkOrderStatuses;

    return getWorkOrderStatuses.map((option) => {
      return {
        ...option,
        label: t(option.label),
        color: getWorkOrderStatusBadgeColor(option.value),
      } as BadgeOption;
    });
  }, [getWorkOrderStatuses]);

  /**
   * Returns the default work order type
   */
  const getDefaultWorkOrderTypeId = () => {
    const defaultWorkOrderType = getDefaultWorkOrderType();

    return defaultWorkOrderType ? defaultWorkOrderType.id : "";
  };

  /**
   * Returns the default car type
   */
  const getDefaultCarType = () => {
    const defaultCarType = getCarTypeByName("Autoturism");

    return defaultCarType ? defaultCarType.id : "";
  };

  /**
   * Returns the default wheel type
   */
  const getDefaultWheelType = () => {
    const defaultWheelType = getWheelTypeByName("Aluminiu");

    return defaultWheelType ? defaultWheelType.id : "";
  };

  /**
   * Returns the draft work order type id
   */
  const getDraftWorkOrderTypeId = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.workOrderTypeId
      ? draft.workOrder.workOrderTypeId
      : form.workOrder.workOrderTypeId;
  };

  /**
   * Returns the draft car type id
   */
  const getDraftCarTypeId = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.carTypeId
      ? draft.workOrder.carTypeId
      : form.workOrder.carTypeId;
  };

  /**
   * Returns the draft car type id
   */
  const getDraftWheelType = (draft: FormBody, form: FormBody) => {
    return draft.tyreService.wheelType
      ? draft.tyreService.wheelType
      : form.tyreService.wheelType;
  };

  /**
   * Returns the draft start date
   */
  const getDraftStartDate = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.startDate
      ? draft.workOrder.startDate
      : form.workOrder.startDate;
  };

  /**
   * Returns the create work order ITP default values
   */
  const getDefaultValuesITP = (draft?: FormBody) => {
    /**
     * Gets the default form
     */
    const defaultForm = getDefaultForm("CREATE_WORK_ORDER") as FormBody;

    /**
     * Gets the default work order type
     */
    const defaultWorkOrderType = getDefaultWorkOrderTypeITP();

    /**
     * Clones the form and the draft
     */
    const clonedForm = cloneDeep(defaultForm);
    const clonedDraft = draft ? cloneDeep(draft) : undefined;

    /**
     * Updates the inputs
     */
    clonedForm.workOrder.startDate = new Date() as any;
    clonedForm.workOrder.workOrderTypeId = defaultWorkOrderType?.id || "";
    clonedForm.workOrder.carTypeId = getDefaultCarType();
    clonedForm.tyreService.wheelType = getDefaultWheelType();

    if (clonedDraft) {
      const workOrderTypeId = getDraftWorkOrderTypeId(clonedDraft, clonedForm);
      const carTypeId = getDraftCarTypeId(clonedDraft, clonedForm);
      const wheelType = getDraftWheelType(clonedDraft, clonedForm);
      const startDate = getDraftStartDate(clonedDraft, clonedForm);

      clonedDraft.workOrder.workOrderTypeId = workOrderTypeId;
      clonedDraft.workOrder.carTypeId = carTypeId;
      clonedDraft.tyreService.wheelType = wheelType;
      clonedDraft.workOrder.startDate = startDate;

      return clonedDraft;
    }

    return clonedForm;
  };

  /**
   * Returns the create work order default values
   */
  const getDefaultValues = (draft?: FormBody) => {
    /**
     * Gets the default form
     */
    const defaultForm = getDefaultForm("CREATE_WORK_ORDER") as FormBody;

    /**
     * Clones the form and the draft
     */
    const clonedForm = cloneDeep(defaultForm);
    const clonedDraft = draft ? cloneDeep(draft) : undefined;

    /**
     * Updates the inputs
     */
    clonedForm.workOrder.startDate = new Date() as any;
    clonedForm.workOrder.workOrderTypeId = getDefaultWorkOrderTypeId();
    clonedForm.workOrder.carTypeId = getDefaultCarType();
    clonedForm.tyreService.wheelType = getDefaultWheelType();

    if (clonedDraft) {
      const workOrderTypeId = getDraftWorkOrderTypeId(clonedDraft, clonedForm);
      const carTypeId = getDraftCarTypeId(clonedDraft, clonedForm);
      const wheelType = getDraftWheelType(clonedDraft, clonedForm);
      const startDate = getDraftStartDate(clonedDraft, clonedForm);

      clonedDraft.workOrder.workOrderTypeId = workOrderTypeId;
      clonedDraft.workOrder.carTypeId = carTypeId;
      clonedDraft.tyreService.wheelType = wheelType;
      clonedDraft.workOrder.startDate = startDate;

      return clonedDraft;
    }

    return clonedForm;
  };

  /**
   * Takes the appointment data and returns the work order form values
   */
  const getAppointmentValues = (appointmentData: AppointmentModel) => {
    /**
     * Gets the default form
     */
    const defaultForm = getDefaultForm("CREATE_WORK_ORDER") as FormBody;
    /**
     * Clones the form and the draft
     */
    const clonedForm = cloneDeep(defaultForm);

    /**
     * Updates the inputs
     */
    clonedForm.workOrder.startDate = new Date() as any;
    clonedForm.workOrder.workOrderTypeId = getDefaultWorkOrderTypeId();
    clonedForm.workOrder.carTypeId = getDefaultCarType();
    clonedForm.tyreService.wheelType = getDefaultWheelType();
    clonedForm.car.plateNumber = appointmentData.carPlateNumber;
    clonedForm.workOrder.clientId = appointmentData.client
      ? appointmentData.client.id
      : "";
    clonedForm.workOrder.clientName = appointmentData.clientName;
    clonedForm.workOrder.phone = appointmentData.phone;
    clonedForm.car.make = appointmentData.carMake || "";
    clonedForm.car.model = appointmentData.carModel || "";
    clonedForm.tyreService.newTyre = appointmentData.options?.newTyre || false;
    clonedForm.tyreHotelEnabled = appointmentData.options?.tyreHotel || false;

    return clonedForm;
  };

  /**
   * Takes the customer order data and returns the work order form values
   */
  const getCustomerOrderValues = (customerOrder: CustomerOrder) => {
    /**
     * Gets the default form
     */
    const defaultForm = getDefaultForm("CREATE_WORK_ORDER") as FormBody;
    /**
     * Clones the form and the draft
     */
    const clonedForm = cloneDeep(defaultForm);

    /**
     * Updates the inputs
     */
    clonedForm.workOrder.startDate = new Date() as any;
    clonedForm.workOrder.workOrderTypeId = getDefaultWorkOrderTypeId();
    clonedForm.workOrder.carTypeId = getDefaultCarType();
    clonedForm.tyreService.wheelType = getDefaultWheelType();
    clonedForm.car.plateNumber = customerOrder.carPlateNumber || "";
    clonedForm.workOrder.clientName = customerOrder.clientName || "";
    clonedForm.workOrder.delegateName =
      customerOrder.metaData?.delegateName || "";
    clonedForm.workOrder.phone = customerOrder.metaData?.phone || "";
    clonedForm.car.make = customerOrder.carMake || "";
    clonedForm.car.model = customerOrder.carModel || "";
    clonedForm.car.vinNumber = customerOrder.metaData?.vinNumber || "";

    if (isTyreService(getDefaultWorkOrderTypeId())) {
      clonedForm.tyreService.carKm = customerOrder.metaData?.carKm || "";
      clonedForm.tyreService.tyreRim = customerOrder.metaData?.tyreRim || "";
      clonedForm.tyreService.tyreWidth =
        customerOrder.metaData?.tyreWidth || "";
      clonedForm.tyreService.tyreHeight =
        customerOrder.metaData?.tyreHeight || "";
    } else {
      clonedForm.carService.carKm = customerOrder.metaData?.carKm || "";
    }

    return clonedForm;
  };

  /**
   * Returns the work order tabs
   */
  const getWorkOrderTabs = [
    {
      key: WORK_ORDER_TABS.GENERAL_DATA,
      label: t("GeneralData"),
      index: 0,
    },
    {
      key: WORK_ORDER_TABS.VEHICLE_DATA,
      label: t("VehicleData"),
      index: 1,
    },
    {
      key: WORK_ORDER_TABS.PRODUCTS_AND_SERVICES,
      label: t("ProductsAndServices"),
      index: 2,
    },
    {
      key: WORK_ORDER_TABS.SUMMARY,
      label: t("Summary"),
      index: 3,
      timer: true,
    },
  ] as Tab[];

  const workOrderCollaboratorTabs = [
    {
      key: WORK_ORDER_COLLABORATOR_TABS.FORM,
      label: t("Form"),
      index: 0,
    },
    {
      key: WORK_ORDER_COLLABORATOR_TABS.SUMMARY,
      label: t("Summary"),
      index: 1,
    },
  ];

  /**
   * Returns the work order ITP tabs
   */
  const getWorkOrderITPTabs = [
    {
      key: WORK_ORDER_ITP_TABS.ITP_FORM,
      label: t("ITPForm"),
      index: 0,
    },
    {
      key: WORK_ORDER_ITP_TABS.SUMMARY,
      label: t("Summary"),
      index: 1,
      timer: true,
    },
  ] as Tab[];

  /**
   * Checks if the provided tab index is valid
   */
  const isValidTab = (
    formBody: FormBody,
    tabIndex: number,
    hasErrors: boolean,
  ) => {
    const { car, workOrder, tyreService, workers } = formBody;

    if (hasErrors) return false;

    if (tabIndex === 0) {
      const plateNumber = car.plateNumber
        ? car.plateNumber.replace(/\s/g, "")
        : "";
      const clientName = workOrder.clientName
        ? workOrder.clientName.replace(/\s/g, "")
        : "";
      return plateNumber.length > 0 && clientName.length > 0;
    }

    if (tabIndex === 1) {
      return tyreService.wheelType && workOrder.carTypeId;
    }

    if (tabIndex === 2) {
      return workers.length > 0;
    }

    if (tabIndex === 3) {
      return !hasErrors;
    }
  };

  /**
   * Returns the error tabs
   */
  const getErrorTabs = (formState: FormState<FormBody>) => {
    /**
     * Initializes the error tabs
     */
    let errorTabs: number[] = [0, 1, 2];

    const generalDataHasError = checkTabForErrors(0, formState);
    const vehicleDataHasError = checkTabForErrors(1, formState);
    const productsHasError = checkTabForErrors(2, formState);

    if (!productsHasError) {
      errorTabs.splice(2, 1);
    }

    if (!vehicleDataHasError) {
      errorTabs.splice(1, 1);
    }

    if (!generalDataHasError) {
      errorTabs.splice(0, 1);
    }

    return errorTabs;
  };

  /**
   * Returns the validated tabs
   */
  const getValidatedTabs = (formBody: FormBody) => {
    const { car, workOrder, workers } = formBody;

    /**
     * Initializes the validated tabs
     */
    const validatedTabs: number[] = [1];

    const plateNumber = car.plateNumber
      ? car.plateNumber.replace(/\s/g, "")
      : "";

    const clientName = workOrder.clientName
      ? workOrder.clientName.replace(/\s/g, "")
      : "";

    if (plateNumber.length < 1 || clientName.length < 1) {
      return validatedTabs;
    }

    if (plateNumber.length > 0 && clientName.length > 0) {
      validatedTabs.push(0);
    }

    if (workers.length > 0) {
      validatedTabs.push(2);
    }

    return validatedTabs;
  };

  /**
   * Checks if the provided tab has any errors
   */
  const checkTabForErrors = (
    tabIndex: number,
    formState: FormState<FormBody>,
  ) => {
    const { errors } = formState;
    const { car, workOrder, tyreService, workers, products } = errors;

    if (tabIndex === 0) {
      if ((car && car.plateNumber) || (workOrder && workOrder.clientName)) {
        return true;
      }
    }

    if (tabIndex === 1 && tyreService) {
      return true;
    }

    if (tabIndex === 2 && (workers || products)) {
      return true;
    }

    return false;
  };

  /**
   * Handles validating a tab
   */
  const validateTab = (props: ValidateTabProps) => {
    const { tabIndex, formState, formBody } = props;

    if (tabIndex === 0) {
      const hasErrors = checkTabForErrors(tabIndex, formState);
      const isValid = isValidTab(formBody, tabIndex, hasErrors);

      return { valid: isValid, hasErrors };
    }

    return { valid: false, hasErrors: false };
  };

  /**
   * Checks if the provided tab should be validated
   */
  const shouldValidateTab = (
    tabIndex: number,
    formState: FormState<FormBody>,
    formBody: FormBody,
  ) => {
    const hasErrors = checkTabForErrors(tabIndex, formState);
    const isValid = isValidTab(formBody, tabIndex, hasErrors);

    return isValid && !hasErrors;
  };

  /**
   * Checks if the provided tab should be invalidated
   */
  const shouldInvalidateTab = (
    tabIndex: number,
    formState: FormState<FormBody>,
    formBody: FormBody,
  ) => {
    const hasErrors = checkTabForErrors(tabIndex, formState);
    const isValid = isValidTab(formBody, tabIndex, hasErrors);

    return !isValid || hasErrors;
  };

  /**
   * Returns the default tabs state
   */
  const getDefaultTabsState = {
    activeTab: 0,
    errorTabs: [],
    disabledTabs: [3],
    validatedTabs: [1],
  } as UseTabsProps["defaults"];

  /**
   * Gets the work order duration
   */
  const getWorkOrderDuration = (startDate: Date) => {
    const { hours, minutes, seconds } = getTimeDifference(
      startDate,
      new Date(),
    );

    /**
     * Formats the time units
     */
    const h = formatTimeUnit(hours);
    const m = formatTimeUnit(minutes);
    const s = formatTimeUnit(seconds);

    return `${h}:${m}:${s}`;
  };

  const buildCustomProduct = (product: ProductForm) => {
    return {
      type: product.type,
      quantity: toInteger(product.quantity),
      price: toFloat(product.price),
      total: toFloat(product.total),
      productId: "",
      name: product!.customProductName,
      um: "BUC",
      isService: false,
      isExpendable: false,
      isCountPrice: false,
      isTyre: product.isTyre,
    };
  };

  /**
   * Returns the form products formatted for the work order request
   */
  const getProducts = (products: ProductForm[]) => {
    const result = products.map((product) => {
      const userProduct = getProductById(product.productId);

      const baseProduct: unknown = userProduct
        ? {
            ...userProduct!,
            organizationProductId: userProduct!.id,
            quantity: toInteger(product.quantity),
            price: toFloat(product.price),
            total: toFloat(product.total),
          }
        : buildCustomProduct(product);

      return baseProduct;
    });

    return (result as any[]).filter((product) => {
      if (!product.name) return false;
      return true;
    }) as ProductForm[];
  };

  /**
   * Handles removing the products from the form body if empty
   */
  const sanitizeProducts = (formBody: FormBody) => {
    if (formBody.products.length < 1) {
      // @ts-ignore
      delete formBody.products;

      formBody.workOrder.total = 0;
      formBody.workOrder.discount = 0;
      formBody.workOrder.subtotal = 0;
    }
  };

  /**
   * Handles sanitizing the work order tyre hotel
   */
  const sanitizeWorkOrderTyreHotel = (formBody: FormBody) => {
    const { tyreHotelEnabled, tyreHotel } = formBody;

    if (tyreHotelEnabled && tyreHotel) {
      if (!Object.values(tyreHotel).some((value) => !!value)) {
        delete formBody.tyreHotel;
      }

      tyreHotel.tyreQuantity = tyreHotel.tyreQuantity
        ? tyreHotel.tyreQuantity.toString()
        : "";
    }

    if (!tyreHotelEnabled) delete formBody.tyreHotel;

    delete formBody.tyreHotelEnabled;
  };

  /**
   * Sanitize work order body
   */
  const sanitizeWorkOrderBody = (clone: any) => {
    const { workOrder } = clone;
    const { workOrderTypeId } = workOrder;

    /**
     * Gets the work order type
     */
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType) {
      if (workOrderType.name === "Service Auto") {
        /**
         * Formats the customer requests
         */
        // clone.carService.customerRequest =
        //   clone.carService.customerRequest.map(
        //     (request: any) => request.value
        //   );

        /**
         * Formats the provider proposals
         */
        // clone.carService.providerProposal =
        //   clone.carService.providerProposal.map(
        //     (request: any) => request.value
        //   );

        // clone.carService.carKm = clone.tyreService.carKm;

        /**
         * Removes unused fields
         */
        delete clone.tyreService;
        delete clone.tyreHotel;
      }

      if (workOrderType.shortName === "VLC") {
        /**
         * Removes unused fields
         */
        delete clone.carService;
      }
    }
  };

  /**
   * Calculates the work order discount
   */
  const calculateWorkOrderDiscount = (formBody: FormBody) => {
    const { workOrder } = formBody;
    const { metaData } = workOrder;
    const { ownProduct, subtotalWithoutHotel, newTyreDiscountValue } =
      metaData || {};

    const subtotal = toSafeInteger(workOrder.subtotal);
    const subtotalForDiscount = subtotalWithoutHotel
      ? subtotalWithoutHotel
      : subtotal;

    const _ownProductDiscount = ownProduct
      ? applyAccountDiscount(
          toSafeInteger(subtotalForDiscount - newTyreDiscountValue),
        )
      : 0;

    const finalDiscount = newTyreDiscountValue + _ownProductDiscount;

    const discountValue = toFloat(finalDiscount) || 0;

    if (discountValue > subtotalForDiscount) {
      return subtotalForDiscount as number;
    }

    return discountValue;
  };

  /**
   * Converts the form inputs to a valid work order request body
   */
  const createWorkOrderBodyITP = (formBody: FormBody) => {
    const clone = cloneDeep(formBody);

    const { workOrder, products } = formBody;
    const { startDate, carTypeId } = workOrder;

    clone.workOrder.endDate = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
    clone.workOrder.startDate = formatDate(
      new Date(startDate),
      "yyyy-MM-dd HH:mm:ss",
    );
    clone.workOrder.duration = getWorkOrderDuration(new Date(startDate));
    clone.car.type = getCarTypeName(carTypeId);

    clone.products = getProducts(products);
    // Do not need recalculate before save
    // clone.workOrder.discount = calculateWorkOrderDiscount(clone);
    // clone.workOrder.total =
    //   toFloat(clone.workOrder.subtotal) - clone.workOrder.discount;

    clone.workOrder.metaData["ITP"] = true;

    /**
     * Sanitizes the products
     */
    sanitizeProducts(clone);

    /**
     * Sanitizes the tyre hotel
     */
    sanitizeWorkOrderTyreHotel(clone);

    /**
     * Sanitizes the work order body
     */
    sanitizeWorkOrderBody(clone);

    /**
     * Removes empty values
     */
    const clean = removeEmpty(clone);

    /**
     * Removes unused fields
     */
    // @ts-ignore
    delete clone.carService;

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clean) as CreateWorkOrderBody;
  };

  /**
   * Converts the form inputs to a valid work order request body
   */
  const createWorkOrderBody = (formBody: FormBody) => {
    const clone = cloneDeep(formBody);

    const { workOrder, tyreService, products } = formBody;
    const { startDate, carTypeId } = workOrder;
    const { wheelType: wheelTypeId } = tyreService;

    clone.workOrder.endDate = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
    clone.workOrder.startDate = formatDate(
      new Date(startDate),
      "yyyy-MM-dd HH:mm:ss",
    );
    clone.workOrder.duration = getWorkOrderDuration(new Date(startDate));
    clone.car.type = getCarTypeName(carTypeId);
    clone.tyreService.wheelType = getWheelTypeName(wheelTypeId);
    clone.products = getProducts(products);
    // Do not need recalculate before save
    // clone.workOrder.discount = calculateWorkOrderDiscount(clone);
    // clone.workOrder.total =
    //   toFloat(clone.workOrder.subtotal) - clone.workOrder.discount;

    if (woRemoveDiscount) {
      clone.workOrder.total = clone.workOrder.subtotal;
      clone.workOrder.discount = 0;
    }

    if (withVat) {
      const subtotal = toSafeInteger(clone.workOrder.subtotal);
      const discount = toSafeInteger(clone.workOrder.discount);
      const vat = calculateVat(subtotal, discount);

      clone.workOrder.tax = vat || clone.workOrder.tax;

      clone.workOrder.metaData["withVat"] = true;
      clone.workOrder.metaData["vatPercentage"] = vatPercentage;
      clone.workOrder.metaData["vatValue"] = clone.workOrder.tax;

      clone.workOrder.total = subtotal - discount + vat;
    }

    /**
     * Sanitizes the products
     */
    sanitizeProducts(clone);

    /**
     * Sanitizes the tyre hotel
     */
    sanitizeWorkOrderTyreHotel(clone);

    /**
     * Sanitizes the work order body
     */
    sanitizeWorkOrderBody(clone);

    /**
     * Removes empty values
     */
    const clean = removeEmpty(clone);

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clean) as CreateWorkOrderBody;
  };

  /**
   * Creates a draft form body
   */
  const createWorkOrderDraftBody = (formBody: FormBody) => {
    const clone = cloneDeep(formBody);
    const { workOrder } = clone;
    const { workOrderTypeId } = workOrder;

    /**
     * Gets the work order type
     */
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    const body: any = {
      workOrder: {
        organizationClientId: clone.workOrder.organizationClientId,
        carPlateNumber: clone.car.plateNumber,
        clientName: clone.workOrder.clientName,
        delegateName: clone.workOrder.delegateName,
        carTypeId: clone.workOrder.carTypeId,
        workOrderTypeId: clone.workOrder.workOrderTypeId,
        duration: clone.workOrder.duration,
        subtotal: clone.workOrder.subtotal,
        discount: clone.workOrder.discount,
        phone: clone.workOrder.phone,
        tax: clone.workOrder.tax,
        total: clone.workOrder.total,
        startDate: formatDate(
          new Date(clone.workOrder.startDate),
          "yyyy-MM-dd HH:mm:ss",
        ),
        endDate: clone.workOrder.endDate,
        description: clone.workOrder.description,
      },
      details: {
        workers: clone.workers,
        car: clone.car,
        carService: clone.carService,
        tyreService: {
          ...clone.tyreService,
          wheelType: getWheelTypeName(clone.tyreService.wheelType),
        },
        products: getProducts(clone.products),
        tyreHotel: clone.tyreHotel,
      },
    };

    if (workOrderType) {
      if (workOrderType.name === "Service Auto") {
        /**
         * Formats the customer requests
         */
        // body.details.carService.customerRequest =
        //   clone.carService.customerRequest.map(
        //     (request: any) => request.value
        //   );

        /**
         * Formats the provider proposals
         */
        // body.details.carService.providerProposal =
        //   clone.carService.providerProposal.map(
        //     (request: any) => request.value
        //   );

        // body.details.carService.carKm = clone.tyreService.carKm;

        /**
         * Removes unused fields
         */
        delete body.details.tyreService;
        delete body.tyreHotel;
      }

      if (workOrderType.shortName === "VLC") {
        /**
         * Removes unused fields
         */
        delete body.details.carService;
      }
    }

    if (clone.tyreHotelEnabled && clone.tyreHotel) {
      const { tyreHotel } = clone;

      if (!Object.values(tyreHotel).some((value) => !!value)) {
        delete body.details.tyreHotel;
      }

      if (body.details.tyreHotel) {
        (body.details.tyreHotel as any).tyreQuantity = tyreHotel.tyreQuantity
          ? tyreHotel.tyreQuantity.toString()
          : "";
      }
    }

    /**
     * Removes empty values
     */
    const clean = removeEmpty(body);

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clean) as CreateWorkOrderDraftBody;
  };

  /**
   * Converts work order car data to inputs
   */
  const convertCarToInputs = (
    inputs: FormBody["car"],
    workOrder: WorkOrder,
  ) => {
    const { car, carPlateNumber } = workOrder;

    inputs.description = car?.description || "";
    inputs.make = car?.make || "";
    inputs.model = car?.model || "";
    inputs.plateNumber = carPlateNumber;
    inputs.type = car?.type || "";
    inputs.vinNumber = car?.vinNumber || "";
    inputs.manufacturingYear = car?.manufacturingYear;

    return inputs;
  };

  /**
   * Converts draft work order car data to inputs
   */
  const convertDraftCarToInputs = (
    inputs: FormBody["car"],
    workOrder: WorkOrder,
  ) => {
    const { details, carPlateNumber } = workOrder;

    if (details) {
      inputs.description =
        details.car && details.car.description ? details.car.description : "";
      inputs.make = details.car && details.car.make ? details.car.make : "";
      inputs.model = details.car && details.car.model ? details.car.model : "";
      inputs.plateNumber =
        details.car && details.car.plateNumber
          ? details.car.plateNumber
          : carPlateNumber;
      inputs.type = details.car && details.car.type ? details.car.type : "";
      inputs.vinNumber =
        details.car && details.car.vinNumber ? details.car.vinNumber : "";
      inputs.manufacturingYear = details.car?.manufacturingYear || "";
    }

    return inputs;
  };

  /**
   * Converts work order data to inputs
   */
  const convertWorkOrderDataToInputs = (
    inputs: FormBody["workOrder"],
    workOrder: WorkOrder,
  ) => {
    inputs.carTypeId =
      workOrder.carTypeId || (workOrder.carType && workOrder.carType.id) || "";
    inputs.clientId = workOrder.organizationClientId
      ? workOrder.organizationClientId
      : "";
    inputs.clientName = workOrder.clientName;
    inputs.delegateName = workOrder.delegateName || "";
    inputs.description = workOrder.description || "";
    inputs.discount = workOrder.discount || 0;
    inputs.duration = workOrder.duration || "";
    inputs.endDate = workOrder.endDate || "";
    inputs.startDate = workOrder.startDate || formatDate(new Date());
    inputs.organizationClientId = workOrder.organizationClientId
      ? workOrder.organizationClientId.toString()
      : "";
    inputs.subtotal = workOrder.subtotal;
    inputs.tax = workOrder.tax;
    inputs.total = workOrder.total;
    inputs.phone = workOrder.phone;
    inputs.workOrderTypeId =
      workOrder.workOrderTypeId || workOrder.workOrderType.id;
    inputs.metaData = workOrder.metaData;

    if (inputs.metaData && inputs.metaData.ownProduct === undefined) {
      inputs.metaData.ownProduct = false;
    }

    return inputs;
  };

  /**
   * Converts draft work order data to inputs
   */
  const convertDraftWorkOrderDataToInputs = (
    inputs: FormBody["workOrder"],
    workOrder: WorkOrder,
  ) => {
    const { details } = workOrder;

    if (details) {
      inputs.carTypeId =
        workOrder.carTypeId ||
        (workOrder.carType && workOrder.carType.id) ||
        "";
      inputs.clientId = "";
      inputs.clientName = workOrder.clientName || "";
      inputs.delegateName = workOrder.delegateName || "";
      inputs.description = workOrder.description || "";
      inputs.discount = workOrder.discount || 0;
      inputs.duration = workOrder.duration || "";
      inputs.endDate = workOrder.endDate || "";
      inputs.startDate = workOrder.startDate || formatDate(new Date());
      inputs.organizationClientId = workOrder.organizationClientId
        ? workOrder.organizationClientId.toString()
        : "";
      inputs.subtotal = workOrder.subtotal;
      inputs.tax = workOrder.tax;
      inputs.total = workOrder.total;
      inputs.phone = workOrder.phone;
      inputs.workOrderTypeId =
        workOrder.workOrderTypeId || workOrder.workOrderType.id;

      return inputs;
    }
  };

  /**
   * Converts work order tyre service to inputs
   */
  const convertTyreServiceToInputs = (
    inputs: FormBody["tyreService"],
    workOrder: WorkOrder,
  ) => {
    const { tyreService } = workOrder;

    if (!tyreService) return inputs;

    /**
     * Gets the wheel type
     */
    const wheelType = getWheelTypeByName(tyreService.wheelType);

    inputs.wheelType = wheelType ? wheelType.id : inputs.wheelType;
    inputs.boltTorque = tyreService.boltTorque || "";
    inputs.carKm = tyreService.carKm || "";
    inputs.newTyre = tyreService.newTyre;
    inputs.tyreHeight = tyreService.tyreHeight || "";
    inputs.tyreWidth = tyreService.tyreWidth || "";
    inputs.tyreRim = tyreService.tyreRim || "";
    inputs.tyrePressure = tyreService.tyrePressure || "";
    inputs.tyreWaste = tyreService.tyreWaste || "";
    inputs.tyreDimensions = tyreService.tyreDimensions || [];
    inputs.dotNumber = tyreService.dotNumber || "";

    return inputs;
  };

  /**
   * Converts work order car service to inputs
   */
  const convertCarServiceToInputs = (
    inputs: FormBody["carService"],
    workOrder: WorkOrder,
    form: FormBody,
  ) => {
    const { carService } = workOrder;

    if (!carService) return inputs;

    inputs.modelType = carService.modelType;
    inputs.mention = carService.mention;
    inputs.customerRequest = carService.customerRequest || [];
    inputs.providerProposal = carService.providerProposal || [];
    form.carService.carKm = carService.carKm || "";
    // form.tyreService.carKm = carService.carKm || "";

    return inputs;
  };

  /**
   * Converts draft work order tyre service to inputs
   */
  const convertDraftTyreServiceToInputs = (
    inputs: FormBody["tyreService"],
    workOrder: WorkOrder,
  ) => {
    const { details } = workOrder;

    if (details && details.tyreService) {
      const { tyreService } = details;

      /**
       * Gets the wheel type
       */
      const wheelType = getWheelTypeByName(tyreService.wheelType);

      inputs.wheelType = wheelType ? wheelType.id : inputs.wheelType;
      inputs.boltTorque = tyreService.boltTorque || "";
      inputs.carKm = tyreService.carKm || "";
      inputs.newTyre = tyreService.newTyre;
      inputs.tyreHeight = tyreService.tyreHeight || "";
      inputs.tyreWidth = tyreService.tyreWidth || "";
      inputs.tyreRim = tyreService.tyreRim || "";
      inputs.tyrePressure = tyreService.tyrePressure || "";
      inputs.tyreWaste = tyreService.tyreWaste || "";

      return inputs;
    }

    return inputs;
  };

  /**
   * Converts draft work order tyre service to inputs
   */
  const convertDraftCarServiceToInputs = (
    inputs: FormBody["carService"],
    workOrder: WorkOrder,
    form: FormBody,
  ) => {
    const { details } = workOrder;

    if (details && details.carService) {
      const { carService } = details;

      inputs.modelType = carService.modelType;
      inputs.mention = carService.mention;
      inputs.customerRequest = carService.customerRequest || [];
      inputs.providerProposal = carService.providerProposal || [];
      form.carService.carKm = carService.carKm || "";
      // form.tyreService.carKm = carService.carKm || "";

      return inputs;
    }

    return inputs;
  };

  /**
   * Converts work order workers to inputs
   */
  const convertWorkersToInputs = (inputs: FormBody, workOrder: WorkOrder) => {
    inputs.workers = workOrder.workers
      ? workOrder.workers.map((worker) => worker.id)
      : [];

    return inputs;
  };

  /**
   * Converts draft work order workers to inputs
   */
  const convertDraftWorkersToInputs = (
    inputs: FormBody,
    workOrder: WorkOrder,
  ) => {
    const { details } = workOrder;

    if (details && details.workers) {
      const { workers } = details;

      inputs.workers = workers as unknown as number[];
    }

    return inputs;
  };

  /**
   * Converts work order products to inputs
   */
  const convertProductsToInputs = (inputs: FormBody, workOrder: WorkOrder) => {
    inputs.products = workOrder.products
      ? workOrder.products.map((product, index) => {
          return {
            type: product.isService ? "service" : "product",
            quantity: product.quantity,
            total: product.total,
            price: product.price,
            productId: product.id,
            isTyre: product.isTyre,
            // id: uniqueId(),
            id: `${product.id}-${index}`,
          };
        })
      : [];
  };

  /**
   * Converts draft work order products to inputs
   */
  const convertDraftProductsToInputs = (
    inputs: FormBody,
    workOrder: WorkOrder,
  ) => {
    const { details } = workOrder;

    if (details && details.products) {
      inputs.products = details.products.map((product, index) => {
        return {
          type: product.isService ? "service" : "product",
          quantity: product.quantity,
          total: product.total,
          price: product.price,
          productId: product.id,
          // id: uniqueId(),
          id: `${product.id}-${index}`,
        };
      });
    }

    return inputs;
  };

  /**
   * Converts the work order to inputs
   */
  const convertWorkOrderToInputs = (data: WorkOrder) => {
    const defaultValues = getDefaultValues();
    const { workOrder, car, carService, tyreService } = defaultValues;

    convertCarToInputs(car, data);
    convertWorkOrderDataToInputs(workOrder, data);
    convertWorkersToInputs(defaultValues, data);
    convertProductsToInputs(defaultValues, data);

    if (data.tyreService) {
      convertTyreServiceToInputs(tyreService, data);
    }

    if (data.carService) {
      convertCarServiceToInputs(carService, data, defaultValues);
    }

    defaultValues.car = car;
    defaultValues.workOrder = workOrder;
    defaultValues.tyreService = tyreService;
    defaultValues.workOrder.metaData = workOrder.metaData;

    return defaultValues;
  };

  /**
   * Converts the draft work order to inputs
   */
  const convertDraftWorkOrderToInputs = (data: WorkOrder) => {
    const defaultValues = getDefaultValues();
    const { workOrder, car, carService, tyreService } = defaultValues;

    if (data.details) {
      const builtWorkOrder: WorkOrder = { ...data, ...data.details };

      convertDraftCarToInputs(car, builtWorkOrder);
      convertDraftWorkOrderDataToInputs(workOrder, builtWorkOrder);
      convertDraftWorkersToInputs(defaultValues, builtWorkOrder);
      convertDraftProductsToInputs(defaultValues, builtWorkOrder);

      if (data.details.tyreService) {
        convertDraftTyreServiceToInputs(tyreService, builtWorkOrder);
      }

      if (data.details.carService) {
        convertDraftCarServiceToInputs(
          carService,
          builtWorkOrder,
          defaultValues,
        );
      }

      defaultValues.car = car;
      defaultValues.workOrder = workOrder;
      defaultValues.tyreService = tyreService;

      if (data.details && data.details.tyreHotel) {
        if (!Array.isArray(data.details.tyreHotel)) {
          // @ts-ignore
          defaultValues.tyreHotel = data.details.tyreHotel;
          defaultValues.tyreHotelEnabled = true;
        }
      }

      return defaultValues;
    }

    return defaultValues;
  };

  /**
   * Checks if the provided work order id has been deleted
   */
  const isWorkOrderDeleted = (
    workOrderId: string,
    events: Event<WorkOrderSlim>[],
  ) => {
    if (events.length < 1) return false;

    const relatedEvents = events.filter(
      ({ data }) => data?.id?.toString() === workOrderId,
    );

    if (relatedEvents.length < 1) return false;

    return relatedEvents
      .map((e) => e.type)
      .includes(ListenType.WORK_ORDER_DELETED);
  };

  /**
   * Handles creating the breadcrumb for the work order controller
   */
  const createBreadcrumb = (
    mode: "edit" | "create" | "drafts" | "createITP" | "editITP",
    workOrder?: WorkOrder,
  ) => {
    const baseBreadcrumb: BreadcrumbPath[] = [
      {
        label: t("Dashboard"),
        link: true,
        url: Paths.Dashboard,
      },
      {
        label: t("WorkOrders"),
        link: true,
        url: Paths.WorkOrders,
      },
      {
        label: t(upperFirst(mode)),
        link: false,
      },
    ];

    if (
      (mode === "edit" || mode === "editITP" || mode === "drafts") &&
      workOrder
    ) {
      baseBreadcrumb.push({
        label: workOrder.uuid,
        link: false,
      });
    }

    return baseBreadcrumb;
  };

  const createCollaboratorWorkOrderBreadcrumb = (
    mode: "edit" | "create" | "view",
    workOrder?: CollaboratorWorkOrder,
  ) => {
    let modeLabel = t(upperFirst(mode));

    if (mode === "view") {
      modeLabel = t("ViewMode");
    }

    const baseBreadcrumb: BreadcrumbPath[] = [
      {
        label: t("Dashboard"),
        link: true,
        url: Paths.Dashboard,
      },
      {
        label: t("CollaboratorWorkOrders"),
        link: true,
        url: Paths.CollaboratorWorkOrders,
      },

      {
        label: modeLabel,
        link: false,
      },
    ];

    if (workOrder) {
      baseBreadcrumb.push({
        label: workOrder.uuid,
        link: false,
        // @ts-ignore
        url: getCollaboratorWorkOrderLink(workOrder.id, mode),
      });
    }

    return baseBreadcrumb;
  };

  /**
   * Handles updating the breadcrumb's view mode
   */
  const updateBreadcrumbViewMode = (
    viewMode: "edit" | "view",
    breadcrumb: BreadcrumbPath[],
    index?: number,
  ) => {
    const cloneBreadcrumb = cloneDeep(breadcrumb);
    const viewModeIndex = cloneBreadcrumb.length - (index || 2);

    cloneBreadcrumb[viewModeIndex] = {
      ...cloneBreadcrumb[viewModeIndex],
      label: t(`${upperFirst(viewMode)}Mode`),
    };

    return cloneBreadcrumb;
  };

  /**
   * Returns the view mode options
   */
  const getViewModeOptions = [
    { value: "edit", label: <Edit /> },
    { value: "view", label: <Eye /> },
  ] as InputChoiceProps["options"];

  /**
   * Returns the work order if found in the provided list
   */
  const getWorkOrderById = (
    workOrderId: string | number,
    list: WorkOrder[],
  ) => {
    const id =
      typeof workOrderId === "string" ? toInteger(workOrderId) : workOrderId;

    return list.find((workOrder) => workOrder.id === id);
  };

  /**
   * Returns the work order body
   */
  const getWorkOrderBody = (formBody: FormBody) => {
    const clone: any = cloneDeep(formBody);
    const { workOrder, carService, tyreService } = formBody;
    const { carTypeId, workOrderTypeId } = workOrder;

    /**
     * Gets the work order type
     */
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);
    clone.car.type = getCarTypeName(carTypeId);

    if (workOrderType) {
      if (carService && workOrderType.name === "Service Auto") {
        /**
         * Removes unused fields
         */
        delete clone.tyreService;
        delete clone.tyreHotel;
      }

      if (tyreService && workOrderType.shortName === "VLC") {
        const { wheelType: wheelTypeId } = tyreService;

        clone.tyreService.wheelType = getWheelTypeName(wheelTypeId);
        clone.tyreService.tyreWaste =
          toString(clone.tyreService.tyreWaste) || "";

        /**
         * Removes unused fields
         */
        delete clone.carService;
      }
    }

    /**
     * Remove unused data
     */
    delete clone.tyreHotelEnabled;
    delete clone.tyreHotel;

    // @ts-ignore
    delete clone.products;
    // Do not need recalculate before save
    // clone.workOrder.discount = calculateWorkOrderDiscount(clone);
    // clone.workOrder.total =
    //   toFloat(clone.workOrder.subtotal) - clone.workOrder.discount;

    if (woRemoveDiscount) {
      clone.workOrder.total = clone.workOrder.subtotal;
      clone.workOrder.discount = 0;
    }

    if (withVat) {
      const subtotal = toSafeInteger(clone.workOrder.subtotal);
      const discount = toSafeInteger(clone.workOrder.discount);
      const vat = calculateVat(subtotal, discount);

      clone.workOrder.tax = vat || clone.workOrder.tax;

      clone.workOrder.metaData["withVat"] = true;
      clone.workOrder.metaData["vatPercentage"] = vatPercentage;
      clone.workOrder.metaData["vatValue"] = clone.workOrder.tax;

      clone.workOrder.total = subtotal - discount + vat;
    }

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clone) as UpdateWorkOrderRelationsBody;
  };

  /**
   * Returns the search pool
   * Used to filter by quick search
   */
  const getSearchPool = (workOrders: WorkOrder[]) => {
    return workOrders.map((workOrder) => {
      return {
        id: workOrder.id,
        status: workOrder.status,
        duration: workOrder.duration,
        clientName: workOrder.clientName,
        total: toCurrency(workOrder.total),
        carPlateNumber: workOrder.carPlateNumber,
        discount: toCurrency(workOrder.discount),
        delegateName: getDelegateNameFromWorkOrder(workOrder),
        paymentType: getPaymentTypeFromWorkOrder(workOrder)?.name,
      };
    });
  };

  /**
   * Returns the work order link in view or edit mode
   */
  const getWorkOrderLink = (id: number, mode: "edit" | "view", itp?: any) => {
    const key = itp ? "edit-itp" : "edit";

    return `/dashboard/workstation-settings/work-orders/${key}/${id}?viewMode=${mode}`;
  };

  const getCollaboratorWorkOrderLink = (
    id: number,
    mode: "create" | "edit" | "view",
  ) => {
    return `/dashboard/workstation-settings/collaborator-work-orders/${mode}/${id}`;
  };

  /**
   * Handles getting the client data
   */
  const getClientData = (workOrder: WorkOrder) => {
    const { clientName, delegateName, organizationClient, phone } = workOrder;
    const { type } = organizationClient || {};

    return [
      {
        key: t("NamePrint"),
        value: clientName,
      },
      {
        key: t("DelegateNamePrint"),
        value: delegateName ? delegateName : "-",
      },
      {
        key: t("PhoneNumberPrint"),
        value: phone ? phone : "-",
      },
      {
        key: t("ClientTypePrint"),
        value: type,
      },
    ];
  };

  /**
   * Handles getting the order details
   */
  const getOrderDetails = (workOrder: WorkOrder) => {
    const { uuid, finished, workOrderType, workOrderTypeName } = workOrder;

    /**
     * Initializes the work order name
     */
    let name = "";

    if (workOrderType && workOrderType.name) {
      name = workOrderType.name;
    }

    if (workOrderTypeName) {
      name = workOrderTypeName;
    }

    return [
      {
        key: t("OrderNumberPrint"),
        value: uuid,
      },
      {
        key: t("DatePrint"),
        value: finished,
      },
      {
        key: t("WorkOrderTypePrint"),
        value: name,
      },
    ];
  };

  /**
   * Handles getting the ITP details
   */
  const getItpDetails = (workOrder: WorkOrder) => {
    const { metaData } = workOrder;

    return [
      {
        key: t("ITPExpirationDatePrint"),
        value: metaData?.expirationDate ? metaData?.expirationDate : "",
      },
    ];
  };

  /**
   * Handles getting the car details
   */
  const getCarDetails = (workOrder: WorkOrder) => {
    const { car, carService, description } = workOrder;
    const {
      plateNumber,
      make,
      model,
      carKm,
      type,
      vinNumber,
      manufacturingYear,
    } = car || {};

    /**
     * Gets the workers
     */
    const workers = workOrder.workers
      ? workOrder.workers.map((worker) => worker.name)
      : [];

    if (carService) {
      const {
        mention,
        carKm: carServiceKm,
        modelType,
        year,
        vinNumber,
      } = carService;
      return [
        {
          key: t("PlateNumberPrint"),
          value: plateNumber ? plateNumber : "-",
        },
        {
          key: t("CarTypePrint"),
          value: type,
        },
        {
          key: t("CarManufacturerPrint"),
          value: make ? make : "-",
        },
        {
          key: t("ModelPrint"),
          value: model ? model : "-",
        },
        {
          key: t("ModelType"),
          value: modelType ? modelType : "-",
        },
        {
          key: t("YearPrint"),
          value: year ? year : "-",
        },
        {
          key: t("CarKmPrint"),
          value: carServiceKm ? formatNumberSeparator(carServiceKm) : "-",
        },
        {
          key: t("VinNumberPrint"),
          value: vinNumber ? vinNumber : "-",
        },
        {
          key: t("ObservationsPrint"),
          value: mention ? mention : "-",
        },
        {
          key: t("WorkersPrint"),
          value: workers.join(", "),
          smallSize: true,
        },
      ];
    }

    return [
      {
        key: t("PlateNumberPrint"),
        value: plateNumber ? plateNumber : "-",
      },
      {
        key: t("CarManufacturerPrint"),
        value: make ? make : "-",
      },
      {
        key: t("ModelPrint"),
        value: model ? model : "-",
      },
      {
        key: t("CarKmPrint"),
        value: carKm ? formatNumberSeparator(carKm) : "-",
      },
      {
        key: t("CarTypePrint"),
        value: type,
      },
      {
        key: t("YearPrint"),
        value: manufacturingYear ? manufacturingYear : "-",
      },
      {
        key: t("VinNumberPrint"),
        value: vinNumber ? vinNumber : "-",
      },
      {
        key: t("ObservationsPrint"),
        value: description ? description : "-",
      },
      {
        key: t("WorkersPrint"),
        value: workers.join(", "),
        smallSize: true,
      },
    ];
  };

  /**
   * Handles getting the car tyre details
   */
  const getCarTyreDetails = (workOrder: WorkOrder) => {
    const { tyreService, car } = workOrder;

    if (!tyreService) return [];

    const {
      tyrePressure,
      newTyre,
      boltTorque,
      tyreWidth,
      tyreHeight,
      tyreRim,
      tyreDimensions,
      dotNumber,
    } = tyreService;

    /**
     * Builds the base tyre dimensions string
     */
    const baseTyreDim = buildTyreDimensionsSummary(
      tyreHeight,
      tyreWidth,
      tyreRim,
      tyrePressure,
      boltTorque,
      dotNumber,
    );

    const result = [
      {
        key: t("TyreDimensionsPrint"),
        value: baseTyreDim,
      },
    ];

    if (tyreDimensions) {
      /**
       * Adds the tyre dimensions
       */
      tyreDimensions.forEach((tyreDim, index) => {
        result.push({
          key: t("TyreDimensions") + ` (${index + 2})`,
          value: buildTyreDimensionsSummary(
            tyreDim.tyreHeight,
            tyreDim.tyreWidth,
            tyreDim.tyreRim,
            tyreDim.tyrePressure,
            tyreDim.boltTorque,
            tyreDim.dotNumber,
          ),
        });
      });
    }

    if (woPdfTyreTorqueNewLine) {
      result.push({
        key: t("BoltTorquePrint"),
        value: boltTorque ? boltTorque + " Nm" : "-",
      });
    }

    if (tyrePressure) {
      result.push({
        key: t("TyrePressurePrint"),
        value: tyrePressure + " Bar",
      });
    }

    /**
     * Adds the final rows
     */
    result.push(
      { key: t("NewTyresPrint"), value: newTyre ? "Da" : "Nu" },
      { key: t("DescriptionPrint"), value: car?.description || "-" },
    );

    return result;
  };

  /**
   * Handles getting the car service details
   */
  const getCarServiceDetails = (workOrder: WorkOrder) => {
    const { carService } = workOrder;

    if (!carService) return [];

    const { customerRequest, providerProposal } = carService;

    return [
      {
        key: t("CustomerRequests"),
        value: customerRequest,
      },
      {
        key: t("ProviderProposals"),
        value: providerProposal,
      },
    ];
  };

  /**
   * Checks if the work order is set as a car service type
   */
  const isCarService = (workOrderTypeId: string | number) => {
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType) {
      return workOrderType.name === "Service Auto";
    }
  };

  /**
   * Checks if the work order is set as a tyre service type
   */
  const isTyreService = (workOrderTypeId: string | number) => {
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType) {
      return workOrderType.shortName === "VLC";
    }
  };

  /**
   * Checks if the summary is a car service type
   */
  const isCarServiceSummary = (formBody: FormBody) => {
    const { workOrder, carService } = formBody;
    const { workOrderTypeId } = workOrder;

    if (isCarService(workOrderTypeId) && carService) return true;
    return false;
  };

  /**
   * Checks if the summary is a tyre service type
   */
  const isTyreServiceSummary = (formBody: FormBody) => {
    const { workOrder, tyreService } = formBody;
    const { workOrderTypeId } = workOrder;

    if (isTyreService(workOrderTypeId) && tyreService) return true;
    return false;
  };

  /**
   * Gets the work order type
   */
  const getWorkOrderType = (workOrder: WorkOrder) => {
    const { workOrderTypeId, workOrderType } = workOrder;
    const foundWorkOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType && workOrderType.name) return workOrderType.name;
    if (foundWorkOrderType) return foundWorkOrderType.name;

    return "-";
  };

  /**
   * Handles building the tyre dimensions summary
   */
  const buildTyreDimensionsSummary = (
    height?: string,
    width?: string,
    rim?: string,
    pressure?: string,
    torque?: string,
    dotNumber?: string,
  ) => {
    let result = "";

    if (width) result += `${width}`;
    if (height) result += `/${height}`;
    if (rim) result += ` R${rim}`;
    if (dotNumber) result += ` | ${dotNumber} DOT`;
    if (pressure) result += ` | ${pressure} Bar`;
    if (torque && !woPdfTyreTorqueNewLine) result += ` | ${torque} NM`;

    return result;
  };

  const filterWorkOrders = (workOrders: WorkOrder[], suspended?: boolean) => {
    const searchValue = suspended
      ? suspendedWorkOrders.searchValue
      : liveWorkOrders.searchValue;

    const filterModels = suspended
      ? suspendedWorkOrders.filterModels
      : liveWorkOrders.filterModels;

    const activeFilters = (liveWorkOrders.statusFilters || [])
      .filter((f) => f.active)
      .map((f) => f.value);

    let result = cloneDeep(workOrders).map((workOrder) => {
      const { workOrderType, workOrderTypeId } = workOrder;

      return {
        ...workOrder,
        workOrderTypeId: workOrderTypeId || workOrderType?.id,
      };
    });

    if (!suspended) {
      result = result.filter((workOrder) => {
        const { status } = workOrder;

        if (status === "upgrade-data") {
          return activeFilters.includes("on-hold");
        }

        return activeFilters.includes(status);
      });
    }

    if (filterModels && filterModels.length > 0) {
      result = result.filter((workOrder) => {
        const inputs = Object.entries(modelsToInputs(filterModels));

        return inputs.every((entry) => {
          const key = entry[0];
          const value = entry[1];

          if (key === "workOrderTypeId") {
            if (value === ITP_WORK_ORDER_TYPE_VALUE) return workOrder.itp;

            return workOrder.workOrderTypeId === value && !workOrder.itp;
          }

          if (key === "organizationId" && value === "all") return true;

          return (workOrder as any)[key] === value;
        });
      });
    }

    const searchPool = getSearchPool(result);

    if (searchPool.length > 0) {
      result = localSearch({
        linkKey: "id",
        source: result,
        searchPool,
        searchValue,
      });
    }

    return result;
  };

  return {
    isValidTab,
    validateTab,
    getErrorTabs,
    isCarService,
    getClientData,
    groupByStatus,
    getCarDetails,
    getSearchPool,
    isTyreService,
    getItpDetails,
    getOrderDetails,
    getWorkOrderType,
    getWorkOrderLink,
    getCarTypeByName,
    getDefaultValues,
    getWorkOrderTabs,
    getValidatedTabs,
    createBreadcrumb,
    getWorkOrderById,
    getWorkOrderBody,
    getDefaultCarType,
    checkTabForErrors,
    shouldValidateTab,
    getCarTyreDetails,
    getWheelTypeByName,
    isWorkOrderDeleted,
    getViewModeOptions,
    isCarServiceSummary,
    shouldInvalidateTab,
    createWorkOrderBody,
    getDefaultTabsState,
    isTyreServiceSummary,
    formatTyreDimensions,
    getWorkOrderStatuses,
    getStatusLabelByValue,
    createWorkOrderBodyITP,
    getWorkOrderTypeByName,
    getCarDataFromWorkOrder,
    getDefaultWorkOrderType,
    getWorkOrderTypeOptions,
    createWorkOrderDraftBody,
    convertWorkOrderToInputs,
    updateBreadcrumbViewMode,
    getDefaultWorkOrderTypeId,
    buildTyreDimensionsSummary,
    getPaymentTypeFromWorkOrder,
    getWorkOrderStatusBadgeColor,
    getDelegateNameFromWorkOrder,
    getPaymentTypeIdFromWorkOrder,
    convertDraftWorkOrderToInputs,
    getTyreDimensionsFromWorkOrder,
    getWorkOrderStatusBadgeOptions,
    getCarServiceDetails,
    getWorkOrderITPTabs,
    getDefaultValuesITP,
    convertCarToInputs,
    convertWorkOrderDataToInputs,
    convertWorkersToInputs,
    convertProductsToInputs,
    getAppointmentValues,
    getCustomerOrderValues,
    filterWorkOrders,
    createCollaboratorWorkOrderBreadcrumb,
    workOrderCollaboratorTabs,
  };
};
