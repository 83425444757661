/**
 * Imports hooks
 */
import { useFieldArray } from "../index";

/**
 * Imports types
 */
import { CollaboratorWoProduct } from "../../types";
import {
  UseCollaboratorWoProductsProps,
  AddCollabProductRowProps,
} from "./useCollaboratorWoProducts.types";

export const useCollaboratorWoProducts = (
  props: UseCollaboratorWoProductsProps,
) => {
  const { control, watch } = props;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "products",
    rules: {
      required: true,
    },
  });

  const watchProducts = watch("products");

  const products = fields.map((field, index) => {
    return {
      ...field,
      ...watchProducts[index],
    };
  });

  const addNewProductRow = () => {
    append({
      code: "",
      name: "",
      um: "BUC",
      quantity: 1,
      price: 1,
      total: 1,
    });
  };

  const AddProductRow = (props: AddCollabProductRowProps) => {
    append(props);
  };

  const updateProductRow = (index: number, row: CollaboratorWoProduct) => {
    update(index, row);
  };

  const removeProductRow = (index: number) => {
    remove(index);
  };

  return {
    products,
    addNewProductRow,
    AddProductRow,
    removeProductRow,
    updateProductRow,
  };
};
