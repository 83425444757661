import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useSelector,
  useApi,
  useSearch,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, Collaborator, TableData } from "../../types";
import {
  SearchCollaboratorsBody,
  SearchCollaboratorsOnSuccess,
  RequestOnError,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CollaboratorsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableRowsLoading, setTableRowsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCollaborators, setTotalCollaborators] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("asc");
  const [modelsInitialized, setModelsInitialized] = useState(false);
  const { userInitialized } = useSelector((state) => state.account);
  const { collaborators } = useSelector((state) => state.collaborators);
  const { apiCalls } = useApi({ withCredentials: true });
  const { dispatchMessage, updateCollaborators } = useActions();

  const debounce = useDebounce();
  const {
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
    getFieldType,
    parseQueryValue,
    filtersToQueryString,
  } = useFilterModelsUtils();
  const { getUserOrganization } = useUserUtils();
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  const handleSearch = (searchValue: string) => {
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchCollaborators(filterModels, 1);
    setActiveFilters(filterModels);
  };

  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchCollaborators(filters, 1);
    }, 500);
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return model.field === "organization_id" || model.field === "status";
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    const organization = getUserOrganization();

    if (organization) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    const statusFilterModel = createFilter({
      field: "status",
      selected: "active",
      type: "dropdown",
    });

    models.push(statusFilterModel);

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  const searchCollaborators = async (filters: FilterModel[], page?: number) => {
    /**
     * Defines the request body
     */
    const reqBody: SearchCollaboratorsBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    const queryString = filtersToQueryString(reqBody.models);
    history.push(`?${queryString}`);

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: SearchCollaboratorsOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        updateCollaborators(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalCollaborators(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchCollaborators(
      reqBody,
      onRequestSuccess,
      onRequestError,
    );
  };

  const updateCollaborator = (collaborator: Collaborator) => {
    const index = findIndex(collaborators, { id: collaborator.id });
    const clonedClients = cloneDeep(collaborators);
    clonedClients[index] = collaborator;

    updateCollaborators(clonedClients);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchCollaborators(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchCollaborators(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    collaborators,
    activeFilters,
    totalCollaborators,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    updateCollaborator,
    handlePageChange,
    setTotalCollaborators,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
