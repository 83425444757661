/**
 * Imports helpers
 */
import { catchError } from "../../useApi";

/**
 * Imports types
 */
import {
  GetCollaboratorApiCall,
  GetCollaboratorResponse,
} from "./GetCollaborator.types";

/**
 * Handles making the api call
 */
export const GetCollaborator: GetCollaboratorApiCall =
  (api) => async (collaboratorId, onSuccess, onError) => {
    try {
      const response = await api.get<GetCollaboratorResponse>(
        `/collaborators/${collaboratorId}`,
      );

      if (response) {
        const { data } = response;
        onSuccess(data, response);
      }
    } catch (error: unknown) {
      catchError(error, onError);
    }
  };
