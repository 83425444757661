import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getWeek } from "date-fns";

import {
  getGeneralInfo,
  getGuestOrganization,
  getGuestOrganizations,
  getGuestOrganizationAppointments,
} from "./publicAppointments.api";

import { ReduxSlice } from "../../features";
import type { PublicAppointmentsState } from "./publicAppointments.types";
import type { AppThunk } from "../../../redux";
import type { SocketEventPayload } from "../../services";
import {
  getTodayDate,
  addBreaksToAppointments,
  combineOverlappingAppointments,
  formatGuestAppointments,
  handleExcludedDays,
} from "./publicAppointments.utils";

import { DEFAULT_CALENDAR_ACTIVE_VIEW } from "../../../constants";
import type { GuestAppointment, GuestOrganization } from "../../../types";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";

const initialState: PublicAppointmentsState = {
  notFoundOrg: false,
  isLoading: false,
  excludedDays: [0],
  carNames: [],
  carModels: [],
  activeGroupId: undefined,
  currentDate: getTodayDate(),
  weekNumber: getWeek(getTodayDate()),
  organizations: [],
  appointments: [],
  calendarData: [],
  calendarActiveView: DEFAULT_CALENDAR_ACTIVE_VIEW,
  activeOrg: undefined,
  calendarStartTime: "08:00",
  calendarEndTime: "18:00",
  calendarBreakInterval: "",
  groupOptions: [],
  orgSuccessMessage: undefined,
  defaultWeekNumber: getWeek(getTodayDate()),
};

export const publicAppointmentsSlice = createSlice({
  name: ReduxSlice.PublicAppointments,
  initialState,
  reducers: {
    updateCalendarActiveView: (state, action: PayloadAction<string>) => {
      state.calendarActiveView = action.payload;
    },
    updateActiveOrg: (state, action: PayloadAction<GuestOrganization>) => {
      state.activeOrg = action.payload;
    },
    updateActiveGroupId: (state, action: PayloadAction<number>) => {
      state.activeGroupId = action.payload;
    },
    updateCalendarData: (state, action: PayloadAction<AppointmentModel[]>) => {
      state.calendarData = action.payload;
    },
    updateCurrentDate(state, action: PayloadAction<Date>) {
      state.currentDate = action.payload;
    },
    updateWeekNumber(state, action: PayloadAction<number>) {
      state.weekNumber = action.payload;
    },
    updateExcludedDays(state, action: PayloadAction<number[]>) {
      state.excludedDays = action.payload;
    },
    initializeSocketConnection(
      state,
      action: PayloadAction<{ organizationId: number }>,
    ) {},
    configureUsingPublicSettings(
      state,
      action: PayloadAction<{
        organizationId: number;
        data: GuestAppointment[];
        weekNumber: number;
      }>,
    ) {
      const data = combineOverlappingAppointments(action.payload.data);
      const organization = state.organizations.find(
        (organization) =>
          organization.id === Number(action.payload.organizationId),
      );

      if (organization && organization.publicSettings) {
        const { data: appointments, weekNumber } = action.payload;
        const { publicSettings } = organization;
        const {
          start,
          end,
          withGroup,
          groupIds,
          successMessage,
          breakInterval,
        } = publicSettings;

        state.excludedDays = handleExcludedDays(weekNumber, publicSettings);

        breakInterval && (state.calendarBreakInterval = breakInterval);
        successMessage && (state.orgSuccessMessage = successMessage);
        start && (state.calendarStartTime = start);
        end && (state.calendarEndTime = end);

        if (withGroup) {
          let existingGroupId =
            state.activeOrg?.id === organization.id && !!state.activeGroupId;

          if (!existingGroupId) {
            if (groupIds.length === 0) {
              state.activeGroupId = organization.appointmentGroups[0].id;
            } else {
              const filteredGroups = organization.appointmentGroups.filter(
                (group) => groupIds.includes(group.id),
              );

              state.activeGroupId = filteredGroups[0].id;
            }
          }

          if (groupIds.length === 0) {
            state.groupOptions = organization.appointmentGroups;
          } else {
            const filteredGroups = organization.appointmentGroups.filter(
              (group) => groupIds.includes(group.id),
            );

            state.groupOptions = filteredGroups;
          }

          /**
           * Filters the appointments by the first group id
           */
          const filteredAppointments = appointments.filter((appointment) => {
            const groupId = existingGroupId
              ? state.activeGroupId
              : groupIds.length === 0
              ? organization.appointmentGroups[0].id
              : organization.appointmentGroups.filter((group) =>
                  groupIds.includes(group.id),
                )[0]?.id;

            return appointment.appointmentGroupId === groupId;
          });

          const appointmentsWithBreaks = addBreaksToAppointments(
            filteredAppointments,
            publicSettings.breakInterval,
            weekNumber,
          );

          state.calendarData = formatGuestAppointments(appointmentsWithBreaks);
        } else {
          const appointmentsWithBreaks = addBreaksToAppointments(
            appointments,
            breakInterval,
            weekNumber,
          );

          state.activeGroupId = undefined;
          state.groupOptions = [];
          state.calendarData = formatGuestAppointments(appointmentsWithBreaks);
        }
      } else {
        state.activeGroupId = undefined;
        state.groupOptions = [];
        state.calendarData = formatGuestAppointments(data);
      }
    },
    updateAppointmentsLive: (
      state,
      action: PayloadAction<SocketEventPayload<GuestAppointment>>,
    ) => {
      const { event, model } = action.payload;

      if (event === "CREATED") {
        state.appointments = [...state.appointments, model];
      }

      if (event === "UPDATED") {
        state.appointments = state.appointments.map((item) =>
          item.id === model.id ? { ...model } : item,
        );
      }

      if (event === "DELETED") {
        state.appointments = state.appointments.filter(
          (item) => item.id !== model.id,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      getGuestOrganizations.matchFulfilled,
      (state, { payload }) => {
        state.organizations = payload.data;
        state.activeOrg = payload.data[0];
      },
    );
    builder.addMatcher(
      getGuestOrganization.matchFulfilled,
      (state, { payload }) => {
        if (payload.data) {
          state.activeOrg = payload.data;
          state.organizations = [payload.data];
        } else {
          state.activeOrg = undefined;
          state.notFoundOrg = true;
        }
      },
    );

    builder.addMatcher(getGeneralInfo.matchFulfilled, (state, { payload }) => {
      state.carModels = payload.data.carModels;
      state.carNames = payload.data.carNames;
    });

    builder.addMatcher(
      getGuestOrganizationAppointments.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      getGuestOrganizationAppointments.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      getGuestOrganizationAppointments.matchFulfilled,
      (state, action) => {
        const { payload } = action;

        state.isLoading = false;
        state.appointments = payload.data;
      },
    );
  },
});

export const changePublicAppointmentsDate =
  (calendarDate: Date, todayDate?: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState().publicAppointments;
    const { excludedDays, activeOrg, weekNumber } = state;
    const today = new Date();
    const thisWeek = getWeek(today);
    const calendarWeek = getWeek(calendarDate);

    if (calendarWeek < thisWeek || calendarWeek > thisWeek + 4) return;

    if (excludedDays.includes(6) && calendarDate.getDay() === 6) {
      if (calendarWeek <= weekNumber) {
        const previousDay = new Date(calendarDate);
        previousDay.setDate(previousDay.getDate() + 2);
        const newWeekNumber = getWeek(previousDay);

        dispatch(updateCurrentDate(previousDay));
        dispatch(updateWeekNumber(newWeekNumber));

        if (activeOrg) {
          dispatch(
            getGuestOrganizationAppointments.initiate({
              weekNumber: newWeekNumber,
              organizationId: String(activeOrg.id),
              year: new Date().getFullYear(),
            }),
          );
        }

        return;
      }
    }

    /**
     * Handles Sunday edge case
     */
    if (calendarDate.getDay() === 0) {
      if (calendarWeek > weekNumber || todayDate) {
        const nextDay = new Date(calendarDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const newWeekNumber = getWeek(nextDay);

        dispatch(updateCurrentDate(nextDay));
        dispatch(updateWeekNumber(newWeekNumber));

        if (activeOrg) {
          dispatch(
            getGuestOrganizationAppointments.initiate({
              weekNumber: newWeekNumber,
              organizationId: String(activeOrg.id),
              year: new Date().getFullYear(),
            }),
          );
        }

        return;
      }

      if (calendarWeek <= weekNumber) {
        const previousDay = new Date(calendarDate);
        previousDay.setDate(
          previousDay.getDate() - (excludedDays.includes(6) ? 2 : 1),
        );
        const newWeekNumber = getWeek(previousDay);

        dispatch(updateCurrentDate(previousDay));
        dispatch(updateWeekNumber(newWeekNumber));

        if (activeOrg) {
          dispatch(
            getGuestOrganizationAppointments.initiate({
              weekNumber: newWeekNumber,
              organizationId: String(activeOrg.id),
              year: new Date().getFullYear(),
            }),
          );
        }

        return;
      }
    }

    if (weekNumber && activeOrg) {
      if (calendarWeek !== weekNumber) {
        const newWeekNumber = getWeek(calendarDate);
        dispatch(
          getGuestOrganizationAppointments.initiate({
            weekNumber: newWeekNumber,
            organizationId: String(activeOrg.id),
            year: new Date().getFullYear(),
          }),
        );
      }
    }

    dispatch(updateCurrentDate(calendarDate));
    dispatch(updateWeekNumber(calendarWeek));
  };

const { updateCurrentDate, updateWeekNumber } = publicAppointmentsSlice.actions;
export const publicAppointmentsActions = publicAppointmentsSlice.actions;
export const publicAppointmentsReducer = publicAppointmentsSlice.reducer;
