import { useMemo } from "react";
import i18next from "i18next";

/**
 * Imports Material UI icons
 */
import {
  HomeWork,
  CreditCard,
  MeetingRoom,
  CardGiftcard,
  DirectionsCar,
  EventOutlined,
  TimelineOutlined,
  EventNoteOutlined,
  DashboardOutlined,
  PeopleAltOutlined,
  AssignmentOutlined,
  ContactPageOutlined,
  VerifiedOutlined,
  DeleteSweepOutlined,
  AccountTreeOutlined,
  ContactMailOutlined,
  MapsHomeWorkOutlined,
  DeveloperBoardOutlined,
  InsertChartOutlinedTwoTone,
  SupervisedUserCircleOutlined,
  SmsOutlined,
  FlagOutlined,
  AnalyticsOutlined,
  ContentPasteGoOutlined,
} from "@mui/icons-material";

/**
 * Imports hooks
 */
import { useTranslation, useAccountSettings } from "../index";

/**
 * Imports types
 */
import { Paths } from "../useNavigation";
import { ListGroup, CreateListGroupsProps } from "./Context";

/**
 * Handles creating the list groups
 */
export const useCreateListGroups = (props: CreateListGroupsProps) => {
  const { expandList, isSuspended } = props;

  const actLang = i18next.language;
  const { t } = useTranslation();
  const { collaboratorModule } = useAccountSettings();

  const listGroups: ListGroup[] = useMemo(() => {
    return [
      {
        listGroupId: 0,
        list: [
          {
            label: t("Dashboard"),
            icon: <DashboardOutlined />,
            active: true,
            disabled: isSuspended,
            path: Paths.Dashboard,
            allowedRoles: ["admin", "operator", "worker"],
          },
          {
            label: t("Appointments"),
            icon: <EventOutlined />,
            disabled: isSuspended,
            path: Paths.Appointments,
          },
          {
            label: t("TyreHotels"),
            icon: <HomeWork />,
            path: Paths.TyreHotels,
            disabled: isSuspended,
          },
          {
            label: t("CustomerOrder"),
            icon: <ContentPasteGoOutlined />,
            allowedRoles: ["admin", "operator", "worker"],
            disabled: isSuspended,
            path: Paths.ClientOrder,
            hiddenOnMobile: true,
          },
        ],
        dividerAfter: true,
      },
      /**
       * Hidden for now
       */
      // {
      //   listGroupId: 1,
      //   list: [
      //     {
      //       label: t("TyreService"),
      //       path: "",
      //       onClick: expandList,
      //       disabled: isSuspended,
      //       list: [
      //         {
      //           label: t("TyreHotel"),
      //           icon: <HomeWork />,
      //           path: Paths.TyreHotels,
      //           disabled: isSuspended
      //         }
      //       ]
      //     }
      //   ],
      //   dividerAfter: true
      // },
      {
        listGroupId: 1,
        visible: collaboratorModule,
        list: [
          {
            label: t("Collaborator"),
            path: "",
            onClick: expandList,
            disabled: isSuspended,
            list: [
              {
                label: t("WorkOrders"),
                icon: <AssignmentOutlined />,
                path: Paths.CollaboratorWorkOrders,
                disabled: isSuspended,
              },
              {
                label: t("Collaborators"),
                icon: <PeopleAltOutlined />,
                path: Paths.Collaborators,
                disabled: isSuspended,
              },
              {
                label: t("Operators"),
                icon: <ContactPageOutlined />,
                path: Paths.CollaboratorWorkers,
                disabled: isSuspended,
              },
              {
                label: t("Reports"),
                icon: <TimelineOutlined />,
                path: Paths.CollaboratorReports,
                disabled: isSuspended,
              },
            ],
          },
        ],
        dividerAfter: true,
      },
      {
        listGroupId: 2,
        allowedRoles: ["admin", "operator"],
        list: [
          {
            label: t("Reports"),
            path: "",
            onClick: expandList,
            disabled: isSuspended,
            list: [
              {
                label: t("Daily"),
                icon: <EventNoteOutlined />,
                path: Paths.DailyReports,
                disabled: isSuspended,
              },
              {
                label: t("Monthly"),
                icon: <TimelineOutlined />,
                path: Paths.MonthlyReports,
                disabled: isSuspended,
              },
              {
                label: t("Company"),
                icon: <MapsHomeWorkOutlined />,
                path: Paths.CompanyReports,
                disabled: isSuspended,
              },
              {
                label: t("Waste"),
                icon: <DeleteSweepOutlined />,
                path: Paths.WasteReports,
                disabled: isSuspended,
              },
              {
                label: t("Workers"),
                icon: <ContactPageOutlined />,
                path: Paths.WorkersReports,
                disabled: isSuspended,
              },
              {
                label: t("WorkOrderProducts"),
                icon: <FlagOutlined />,
                path: Paths.WorkOrderProductsReports,
                disabled: isSuspended,
              },
              // REMOVED DUE TO https://github.com/Drfonix/atelierulmeu-frontend-v3/issues/538
              // {
              //   label: t("Timesheet"),
              //   icon: <AccessTimeOutlined />,
              //   path: Paths.TimesheetReports,
              // },
              {
                label: t("Advanced"),
                icon: <InsertChartOutlinedTwoTone />,
                path: Paths.AdvancedReports,
                disabled: isSuspended,
              },
              {
                label: t("SuspendedWorkOrdersShort"),
                icon: <AnalyticsOutlined />,
                path: Paths.SuspendedWoReports,
                disabled: isSuspended,
              },
              {
                label: t("Appointments"),
                icon: <EventOutlined />,
                path: Paths.AppointmentsReports,
                disabled: isSuspended,
                divider: true,
              },
              {
                label: t("SMS"),
                icon: <SmsOutlined />,
                path: Paths.SMSReports,
                disabled: isSuspended,
              },
            ],
          },
        ],
        dividerAfter: true,
      },
      {
        listGroupId: 3,
        allowedRoles: ["admin", "operator"],
        list: [
          {
            label: t("WorkstationSettings"),
            path: "",
            onClick: expandList,
            disabled: isSuspended,
            list: [
              {
                label: t("WorkOrders"),
                icon: <AssignmentOutlined />,
                path: Paths.WorkOrders,
                disabled: isSuspended,
              },
              {
                label: t("Clients"),
                icon: <PeopleAltOutlined />,
                path: Paths.Clients,
                disabled: isSuspended,
              },
              {
                label: t("Products"),
                icon: <DeveloperBoardOutlined />,
                path: Paths.Products,
                disabled: isSuspended,
              },
              {
                label: t("Services"),
                icon: <DeveloperBoardOutlined />,
                path: Paths.Services,
                disabled: isSuspended,
              },
              {
                label: t("LoyaltyCards"),
                icon: <CardGiftcard />,
                path: Paths.LoyaltyCards,
                disabled: isSuspended,
              },
              {
                label: t("Guaranties"),
                icon: <VerifiedOutlined />,
                path: "/dashboard/workstation-settings/guaranties",
              },
            ],
          },
        ],
        dividerAfter: true,
      },
      {
        listGroupId: 4,
        allowedRoles: ["admin"],
        list: [
          {
            label: t("AccountSettings"),
            path: "",
            onClick: expandList,
            list: [
              // {
              //   label: t("Management"),
              //   icon: <DonutSmallOutlined />,
              //   path: "/dashboard/account-settings/management"
              // },
              {
                label: t("Organizations"),
                icon: <AccountTreeOutlined />,
                path: Paths.Organizations,
              },
              {
                label: t("Users"),
                icon: <SupervisedUserCircleOutlined />,
                path: Paths.Users,
              },
              {
                label: t("Workers"),
                icon: <ContactMailOutlined />,
                path: Paths.Workers,
              },
              // REMOVED DUE TO https://github.com/Drfonix/atelierulmeu-frontend-v3/issues/538
              // {
              //   label: t("Timesheet"),
              //   icon: <AccessTimeOutlined />,
              //   path: Paths.Timesheet,
              // },
              {
                label: t("PaymentMethods"),
                icon: <CreditCard />,
                path: Paths.PaymentMethods,
              },
              {
                label: t("CarTypes"),
                icon: <DirectionsCar />,
                path: Paths.CarTypes,
              },
              {
                label: t("AppointmentGroups"),
                icon: <MeetingRoom />,
                path: Paths.AppointmentGroups,
              },
              {
                label: t("SMSMessages"),
                icon: <SmsOutlined />,
                path: Paths.SMSMessages,
              },
              // {
              //   label: t("Orders"),
              //   icon: <ListAlt />,
              //   path: "/dashboard/account-settings/orders"
              // },
              // {
              //   label: t("Update"),
              //   icon: <Update />,
              //   path: "/dashboard/account-settings/update"
              // },
              // {
              //   label: t("SuspendAccount"),
              //   icon: <DeleteForeverOutlined />,
              //   path: "/dashboard/account-settings/suspend"
              // }
            ],
          },
        ],
        dividerAfter: true,
      },
    ] as ListGroup[];
    // eslint-disable-next-line
  }, [isSuspended, actLang, collaboratorModule]);

  return listGroups;
};
