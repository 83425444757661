import { apiService } from "../../services";

import type {
  GetGeneralInfoResponse,
  GetGuestOrganizationResponse,
  GetGuestOrganizationsResponse,
  CreateGuestAppointmentRequest,
  CreateGuestAppointmentResponse,
  GetGuestOrganizationAppointmentsRequest,
  GetGuestOrganizationAppointmentsResponse,
} from "./publicAppointments.types";

const publicAppointmentsApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Query - Gets the general info
     */
    getGeneralInfo: builder.query<GetGeneralInfoResponse, void>({
      query: () => ({
        guest: true,
        url: "/guest/general-info",
        method: "get",
      }),
    }),

    /**
     * Query - Gets a guest organization
     */
    getGuestOrganization: builder.query<
      GetGuestOrganizationResponse,
      { slug: string }
    >({
      query: ({ slug }) => ({
        guest: true,
        url: `/guest/organizations/${slug}`,
        method: "get",
      }),
    }),

    /**
     * Query - Gets the guest organizations
     */
    getGuestOrganizations: builder.query<GetGuestOrganizationsResponse, void>({
      query: () => ({
        guest: true,
        url: "/guest/organizations",
        method: "get",
      }),
    }),

    /**
     * Query - Gets the guest org appointments
     */
    getGuestOrganizationAppointments: builder.query<
      GetGuestOrganizationAppointmentsResponse,
      GetGuestOrganizationAppointmentsRequest
    >({
      query: ({ organizationId, year, weekNumber }) => ({
        guest: true,
        url: `/guest/organizations/${organizationId}/appointments/${year}/${weekNumber}`,
        method: "get",
      }),
    }),

    /**
     * Mutation - Creates a guest appointment
     */
    postGuestAppointment: builder.query<
      CreateGuestAppointmentResponse,
      CreateGuestAppointmentRequest
    >({
      query: ({ orgId, requestBody }) => ({
        guest: true,
        url: `/guest/organizations/${orgId}/appointments`,
        method: "post",
        requestBody,
      }),
    }),
  }),
});

export const {
  useGetGeneralInfoQuery,
  useLazyGetGeneralInfoQuery,
  useGetGuestOrganizationQuery,
  usePostGuestAppointmentQuery,
  useGetGuestOrganizationsQuery,
  useLazyGetGuestOrganizationQuery,
  useLazyPostGuestAppointmentQuery,
  useLazyGetGuestOrganizationsQuery,
  useGetGuestOrganizationAppointmentsQuery,
  useLazyGetGuestOrganizationAppointmentsQuery,
} = publicAppointmentsApi;
export const {
  endpoints: {
    getGeneralInfo,
    getGuestOrganization,
    postGuestAppointment,
    getGuestOrganizations,
    getGuestOrganizationAppointments,
  },
} = publicAppointmentsApi;
