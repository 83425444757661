import { LanguageResource } from "./index";

/**
 * Defines the english to english translations
 */
export const en_en: LanguageResource = {
  /**
   * General
   */
  VAT: "VAT",
  VATPrint: "TVA",
  PriceBase: "Price base",
  AddA: "Add a",
  Home: "Home",
  LoadData: "Load data",
  HideData: "Hide data",
  ShowData: "Show data",
  AssociatedWith: "Associated with",
  AddNewWorker: "Add new worker",
  BirthDate: "Birthdate",
  Account: "Account",
  AccountSettings: "Account settings",
  AccountStatus: "Account status",
  AccountSummary: "Account summary",
  AccountType: "Account type",
  ActionCannotBeUndone: "This action <strong>cannot</strong> be undone.",
  Activate: "Activate",
  ActivePackage: "Active package",
  AddNewCarType: "Add new car type",
  AddNewClient: "Add new client",
  AddNewCondition: "Add new condition",
  AddNewContact: "Add new contact",
  AddNewLoyaltyCard: "Add new loyalty card",
  AddNewOrganization: "Add new organization",
  AddNewProduct: "Add new product",
  AddNewTyreHotel: "Add new tyre hotel",
  AddNewPaymentMethod: "Add new payment method",
  AddNewAppointmentGroup: "Add new appointment group",
  AddNewUser: "Add new user",
  AddNewGuaranty: "Add new guaranty",
  AddNewTimesheet: "Add new timesheet",
  AddNewAppointment: "Add new appointment",
  AddProductOrService: "Add product / service",
  AddTyreHotel: "Add tyre hotel",
  Advanced: "Advanced",
  AdvancedReport: "Advanced report",
  AdvancedReports: "Advanced report",
  AppointmentsReport: "Appointments report",
  SuspendedWoReport: "Suspended work orders report",
  All: "All",
  Appearance: "Appearance",
  Appointment: "Appointment",
  AppointmentGroups: "Appointment groups",
  Appointments: "Appointments",
  AppointmentsIntroText: "Track and manage your appointments.",
  AreYouAbsolutelySure: "Are you absolutely sure?",
  AreYouARobot: "Are you a robot?",
  AreYouSure: "Are you sure?",
  AvailableModulesForAccount: "Available modules for your account:",
  BackToDashboard: "Go back to dashboard",
  BeforeYouSuspendTheAccount:
    "Before you suspend the account associated with <strong> {{account}} </strong>, please note:",
  BiggerThan: "Bigger than",
  BiggerThanOrEqual: "Bigger than or equal",
  BoltTorque: "Bolt torque (Nm)",
  BoltTorqueShort: "Torque",
  BoltTorquePrint: "Cuplu de strangere (Nm)",
  CalculatingDynamicPricing: "Calculating dynamic pricing.",
  Cancel: "Cancel",
  CardActive: "Card active",
  CarData: "Car data",
  CardNumber: "Card number",
  CarType: "Car type",
  CarTypePrint: "Tipul de mașină",
  CarTypes: "Car types",
  CarTypesIntroText: "Manage and track your car types.",
  AppointmentGroupsIntroText: "Add and manage your appointment groups.",
  ChangeLanguage: "Change the language used in the user interface.",
  ChangePassword: "Change password",
  CheckYourNotifications: "Check your notifications for details.",
  Client: "Client",
  ClientCode: "Client code",
  ClientData: "Client data",
  ClientDataPrint: "Date client",
  ClientName: "Client name",
  Clients: "Clients",
  ClientsIntroText: "Manage and track your customers.",
  ClientType: "Client type",
  ClientTypePrint: "Tipul de client",
  CloseAccount: "Close account",
  CloseAccountInfo:
    "Closing your account will permanently remove your data and content.",
  Closed: "Closed",
  Company: "Company",
  CompanyData: "Company data",
  CompanyMonthlyReportsTotalization: "Company monthly reports totalization",
  CompanyMonthlyReportTotalized: "Monthly report totalization",
  CompanyReport: "Company report",
  Condition: "Condition",
  ConfirmNewPassword: "Confirm new password",
  ContactAdminToExtendPackage:
    "Please contact your organization administrator in order to extend your current package.",
  ContactData: "Contact data",
  Contracts: "Contracts",
  Copyright: "© {{year}} VehiGo. All rights reserved",
  CopyrightShort: "© {{year}}",
  Create: "Create",
  CreateITP: "Create ITP",
  CreatedAt: "Created date",
  UpdatedAt: "Updated date",
  CreatedTime: "Created time",
  CreateTyreHotel: "Create tyre hotel",
  CreateWorkOrders: "Create work orders",
  CreatingTyreHotel: "Creating tyre hotel",
  CurrentMonth: "Current month",
  CurrentPassword: "Current password",
  CurrentStatus: "Current status",
  CurrentWeek: "Current week",
  CustomizeAppearance: "Customize how the app looks on your device.",
  Daily: "Daily",
  DailyReport: "Daily report",
  Dashboard: "Dashboard",
  DashboardPageIntro: "Create, manage and track your customer orders.",
  Date: "Date",
  DatePrint: "Dată",
  DateRange: "Date range",
  Deactivate: "Deactivate",
  Delegate: "Delegate",
  DelegateName: "Delegate name",
  DelegateNamePrint: "Numele delegatului",
  Delete: "Delete",
  DeleteAllNotifications: "Delete all notifications",
  DeleteClientWarning:
    "This will <strong>permanently</strong> delete the client.",
  DeleteInProgress: "Delete in progress",
  DeleteLoyaltyCardWarning:
    "This will <strong>permanently</strong> delete the loyalty card.",
  DeleteNotificationsWarning: "You are about to delete all the notifications.",
  DeleteTimesheetWarning:
    "This will <strong> permanently </strong> delete the timesheet.",
  DeleteOrganizationWarning:
    "This will <strong>permanently</strong> delete the organization.",
  DeleteProductWarning:
    "This will <strong>permanently</strong> delete the product.",
  DeleteServiceWarning:
    "This will <strong>permanently</strong> delete this service.",
  DeleteThisProductWarning:
    "This will <strong>permanently</strong> delete this product.",
  DeleteTyreHotelWarning:
    "This will <strong>permanently</strong> delete the tyre hotel.",
  DeleteUserWarning: "This will <strong>permanently</strong> delete the user.",
  DeleteAccountWarning:
    "This will <strong> permanently </strong> delete the account.",
  DeleteWorkerWarning:
    "This will <strong>permanently</strong> delete the worker.",
  DeleteWorkOrderWarning:
    "This will <strong>permanently</strong> delete the work order.",
  DeleteGuarantyWarning:
    "This will <strong> permanently </strong> delete the guaranty.",
  DeletingTyreHotel: "Deleting tyre hotel",
  DeletingWorkOrder: "Deleting work order",
  DeletingTimesheet: "Deleting timesheet",
  DepositTyreHotel: "Deposit / Tyre hotel",
  Description: "Description",
  DescriptionPrint: "Descriere",
  DetailedReport: "Detailed report",
  Discount: "Discount",
  DiscountPrint: "Reducere",
  DiscountType: "Discount type",
  DiscountTypePercentage: "Percentage",
  DiscountTypeValue: "Value (RON)",
  DiscountValue: "Discount value",
  DistanceDone: "Distance done (km)",
  DocumentPrefix: "Document prefix",
  DocumentSuffix: "Document suffix",
  Done: "Done",
  DownloadAsPDF: "Download as PDF",
  DropHereToDelete: "Drop here to delete",
  ITPForm: "ITP form",
  Edit: "Edit",
  EditITP: "Edit ITP",
  EditStatus: "Edit status",
  EditClient: "Edit client",
  EditLoyaltyCard: "Edit loyalty card",
  EditProduct: "Edit product",
  EditService: "Edit service",
  EditTyreHotel: "Edit tyre hotel",
  ViewTyreHotel: "View tyre hotel",
  EditWorkOrder: "Edit work order",
  EditWorkOrderITP: "Edit ITP",
  EditGuarantee: "Edit guarantee",
  EditWorkOrderIntro: "Update an order quick and easy.",
  EditDraftWorkOrder: "Edit draft work order",
  EditDraftWorkOrderIntro: "Update a draft work order quick and easy.",
  EditCustomerOrder: "Edit customer order",
  CreateWorkOrder: "Create work order",
  ElapsedTime: "Elapsed time",
  Email: "Email",
  en_en: "EN",
  en_enLabel: "English",
  en_hu: "HU",
  en_huLabel: "Magyar",
  en_ro: "RO",
  en_roLabel: "Română",
  EntityType: "Entity type",
  Equals: "Equals",
  Expendable: "Expendable",
  ExpiresIn: "Expires in",
  Export: "Export",
  Field: "Field",
  FirstName: "First name",
  LastName: "Last name",
  FlatPriceExplanation: "The new price is the same as the standard price.",
  ForClient: "For client",
  ForWarehouse: "For warehouse",
  General: "General",
  GeneralData: "General data",
  GenerateCardNumber: "Generate card number",
  GettingProductAndServices: "Getting products and services.",
  GettingWorkOrderData: "Getting work order data",
  GroupByDay: "Group by day",
  HigherPriceExplanation: "The new price is higher than the standard price.",
  HotelOrDeposit: "Hotel / Deposit",
  InProgress: "In progress",
  InvalidEmail: "Invalid email",
  InvalidNumber: "Invalid number",
  InvalidPlateNumber: "Invalid plate number",
  IUnderstandDeleteCard: "I understand, delete this card.",
  IUnderstandDeleteClient: "I understand, delete this client.",
  IUnderstandDeleteHotel: "I understand, delete this tyre hotel.",
  IUnderstandDeleteOrganization: "I understand, delete this organization.",
  IUnderstandDeleteProduct: "I understand, delete this product.",
  IUnderstandDeleteUser: "I understand, delete this user.",
  IUnderstandDeleteAccount: "I understand, delete this account.",
  IUnderstandDeleteWorker: "I understand, delete this worker.",
  IUnderstandDeleteWorkOrder: "I understand, delete this work order.",
  IUnderstandDeleteGuaranty: "I understand, delete this guaranty.",
  IUnderstandDeleteTimesheet: "I understand, delete this timesheet.",
  Language: "Language",
  LegalEntity: "Legal entity",
  LessThan: "Less than",
  LessThanOrEqual: "Less than or equal",
  LoadingOptions: "Loading options",
  Location: "Location",
  Logout: "Log out",
  LowerPriceExplanation: "The new price is lower than the standard price.",
  LoyalClient: "Loyal client",
  LoyalClients: "Loyal clients",
  LoyaltyCard: "Loyalty card",
  LoyaltyCardPrint: "Card de fidelitate",
  LoyaltyCards: "Loyalty cards",
  LoyaltyCardsIntroText: "Manage and track your loyalty cards.",
  Management: "Management",
  Manufacturer: "Manufacturer",
  MarkAllAsRead: "Mark all as read",
  MarkAsRead: "Mark as read",
  MatchingCardFound: "Matching card found",
  MessageBoxError: "Error",
  MessageBoxInfo: "Info",
  MessageBoxSuccess: "Success",
  MessageBoxWarning: "Warning",
  MessageShownInError:
    "If you believe this message in shown in error, you can contact us, using the button bellow",
  Min1Worker: "Min. 1 worker",
  Model: "Model",
  ModelPrint: "Model",
  Monthly: "Monthly",
  MonthlyReport: "Monthly report",
  MonthlyTotalOrders: "Monthly total orders",
  New: "New",
  Skipped: "Skipped",
  NewPassword: "New password",
  NewPrice: "New price",
  NewTyre: "New tyre",
  NewTyres: "New tyres",
  NewTyresPrint: "Anvelope noi",
  NewVersionAvailable: "There is a newer version available. Please refresh",
  NewWorkOrder: "New work order",
  ITP: "ITP",
  ITPIntroText: "Create a new ITP quick and easy.",
  NewWorkOrderIntro: "Create a customer order quick and easy.",
  NextStep: "Next step",
  No: "No",
  NoBarcode:
    "We're sorry, no barcode was detected. Please try again or enter the bardcode manually bellow.",
  NoNewNotifications: "You have no new notifications.",
  NoOptions: "No options",
  Options: "Options",
  NoScanResultsPleaseTryAgain: "No cards found. Please try again.",
  NoSearchResultsFound: "No search results found.",
  NotFound: "Not found",
  Notifications: "Notifications",
  NotificationsIntroText: "Manage and track your notifications",
  NoUnreadNotifications: "You have no unread notifications.",
  Observations: "Observations",
  Observation: "Observation",
  ObservationsPrint: "Observații",
  OnHold: "On hold",
  OpenNotifications: "Open notifications",
  OrderNumber: "Order number",
  OrderNumberPrint: "Număr de comandă",
  Orders: "Orders",
  Organizations: "Organizations",
  OrganizationsIntroText: "Manage and track your organizations.",
  PastMonth: "Past month",
  PastWeek: "Past week",
  Payment: "Payment",
  PaymentMethods: "Payment methods",
  PaymentType: "Payment type",
  PaymentTypes: "Payment types",
  Person: "Person",
  Phone: "Phone",
  PhoneFix: "Telephone",
  PhoneMobile: "Mobile phone",
  PhoneNumber: "Phone number",
  PhoneNumberPrint: "Număr de telefon",
  PickDateRange: "Pick a date range",
  PlateNumber: "Plate number",
  PleaseCompleteTheRecaptcha: "Please complete the recaptcha",
  PleaseContactAccountAdmin:
    "Please contact your account admin to recover your account.",
  PleaseProvideAtLeastOneFilter: "Please provide at least one filter",
  PleaseUseBiggerScreen:
    "Please use a screen that's at least <strong> 1100 pixels </strong> wide, we apologize for the inconvenience.",
  PostCode: "Post code",
  PreviousStep: "Previous step",
  PriceActivated: "Price activated",
  PriceConditions: "Price conditions",
  Print: "Print",
  ProcessingYourRequest: "Proccesing your request",
  ProductIsInactive:
    "The price of this product is deactivated. This can be changed in Workstation Settings / Products",
  ProductName: "Product name",
  Products: "Products",
  ProductsPrint: "Produse",
  ProductsAndServices: "Products / Services",
  ProductsIntroText: "Manage and track your products.",
  ServicesIntroText: "Manage and track your services.",
  ProductType: "Product type",
  Quantity: "Quantity",
  QuantityPrint: "Cantitate",
  QueryInterval: "Query interval",
  RecoverAccount: "Recover account",
  RecoverAccountInfo:
    "Your account is currently suspended therefore some features are not available.",
  RecoverAccountRestoreFeatures:
    "You can recover your account to restore the features at any time.",
  RecoveringYourAccount: "Recovering your account",
  RegNumber: "Registration number",
  ReleaseToDelete: "Release to delete",
  RemoveThisNotification: "Remove this notification",
  Reports: "Reports",
  ReportsPageIntroText: "Reports page intro text",
  ReportTitle: "Report title",
  RequestTimeout: "Your request timed out",
  RequiredField: "Required field",
  ResetFormWarning: "You are about to reset the form.",
  Save: "Save",
  SaveDraft: "Save draft",
  SavedSuccessfully: "Saved successfully",
  Saving: "Saving",
  ScanCard: "Scan card",
  Scheduled: "Scheduled",
  SelectAProduct: "Select a product",
  SelectAService: "Select a service",
  SelectExpendable: "Select expendable",
  Settings: "Settings",
  Shipments: "Shipments",
  ShortName: "Short name",
  ShowResults: "Show results",
  SmartTableResultsCount: "results",
  SomeFeaturesNoLongerAvailable: "Some features will no longer be available",
  SortOrder: "Order",
  SortOrderField: "Field",
  Sta: "Standard",
  Status: "Status",
  StillFetchingProductsPleaseTryAgain:
    "Sorry! We're still fetching your products and services. Please try again.",
  Subtotal: "Subtotal",
  SubtotalPrint: "Subtotal",
  Summary: "Summary",
  Support: "Support",
  SuspendAccount: "Suspend account",
  SuspendAccountInfoReviewed:
    "I have reviewed the information above. I am ready to suspend my account.",
  SuspendAccountNotPermanent: "Suspending your account is not permanent.",
  SuspendAccountReactivate:
    "You can reactivate at any time, and your data will remain in the account while it is inactive.",
  SuspendAvailableFeatures:
    "Only the account settings will be available for admin accounts, for the purpose of reactivating the suspended account.",
  SuspendImpactsUsers: "<strong>{{userCount}} users will be impacted.</strong>",
  SuspendingYourAccount: "Suspending your account",
  StandardPrice: "Standard price: <strong>{{price}}</strong>",
  Switch: "Switch",
  SwitchAccount: "Switch account",
  Syncronizing: "Syncronizing",
  TaskCompletion: "Task completion",
  TaxAndRegistration: "VAT / Registration",
  TaxNumber: "VAT number",
  Timesheet: "Timesheet",
  TimesheetExample: "Eg: M-F 08:00 - 18:00 | Sat 08:00 - 13:00 | Sun Closed",
  Timesheets: "Timesheets",
  TimesheetsIntroText: "Manage and track the timesheet of your employees.",
  Today: "Today",
  Total: "Total",
  TotalVAT: "Total (with VAT)",
  TotalPrint: "Total",
  TotalPrintVAT: "Total (cu TVA)",
  TotalOrders: "Total orders",
  TotalQuantity: "Total quantity",
  TotalReport: "Total report",
  Type: "Type",
  TyreData: "Tyre data",
  TyreDimensions: "Tyre dimensions",
  TyreDimensionsPrint: "Dimensiunile anvelopei",
  TyreDimensionsShort: "Tyre dim.",
  TyreHeight: "Tyre height",
  TyreHeightShort: "Height",
  TyreHotel: "Tyre hotel",
  TyreHotels: "Tyre hotels",
  TyreHotelsIntroText: "Manage and track your tyre hotels.",
  TyreName: "Tyre name",
  TyrePressure: "Tyre pressure (Bar)",
  TyrePressureShort: "Pressure",
  TyrePressurePrint: "Presiunea pneurilor (Bar)",
  TyreQuantity: "Tyre quantity",
  TyreRim: "Tyre rim",
  TyreRimShort: "Rim",
  TyreService: "Tyre service",
  TyreServicePackageDescription:
    "This package contains all tyre service related features. The price is per workplace per month, and the default duration is 30 days.",
  TyreWaste: "Tyre waste",
  TyreWasteShort: "Waste",
  TyreWidth: "Tyre width",
  TyreWidthShort: "Width",
  UmPlaceholder: "PC",
  UnitOfMeasure: "Unit of measure",
  UnknownTranslation: "[Unknown Translation]",
  Unlock: "Unlock",
  Unread: "Unread",
  UnsupportedScreenResolution: "Your screen resolution is not supported",
  Update: "Update",
  UpdateInProgress: "Update in progress",
  UpdatePasswordText:
    "It's a good idea to use a strong password that you're not using elsewhere.",
  UpdateWorkOrder: "Update work order",
  UpdateYourPassword: "Update your password",
  UpdatingTyreHotel: "Updating tyre hotel",
  UpdatingWorkOrder: "Updating work order",
  UploadExcel: "Upload excel",
  Users: "Users",
  UsersIntroText: "Manage and track your organization's user accounts.",
  Value: "Value",
  VehicleData: "Vehicle data",
  VehicleType: "Vehicle type",
  ViewAllFilters: "View all filters",
  ViewProfile: "View profile",
  ViewWorkOrder: "View work order",
  ViewWorkOrderITP: "View ITP",
  ViewWorkOrderIntro: "Explore the work order information.",
  VinNumber: "Vin number",
  VsLastMonth: "vs last month",
  VsLastWeek: "vs last week",
  VsLastYear: "vs last year",
  VsThisMonth: "vs this month",
  VsThisWeek: "vs this week",
  VsThisYear: "vs this year",
  VsYesterday: "vs yesterday",
  Waste: "Waste",
  WasteReport: "Waste report",
  WeApologizeForTheInconvenience: "We apologize for the inconvenience.",
  WeeklyTotalOrders: "Weekly total orders",
  WelcomeBack: "Welcome back",
  WheelType: "Wheel type",
  WorkerAndOperatorAccountsRestrictedAccess:
    "All worker and operator accounts will no longer be able to access the content until the admin account is restored.",
  Workers: "Workers",
  WorkersPrint: "Muncitori",
  WorkersReport: "Workers report",
  WorkOrder: "Work order",
  WorkOrderITP: "Work order - ITP",
  WorkOrderCreated: "Work order created successfully",
  WorkOrderNotFound: "We could not find the work order you are looking for.",
  WorkOrderRemoved:
    "This work order has been deleted and is no longer available.",
  DraftWorkOrderNotFound:
    "We could not find the draft work order you are looking for.",
  DraftWorkOrderRemoved:
    "This draft work order has been deleted and is no longer available.",
  WorkOrders: "Work orders",
  WorkOrdersCountTotal: "Work orders totalized quantity",
  WorkOrdersIntroText: "Manage and track your customer orders.",
  WorkersIntroText: "Manage and track your employees.",
  WorkOrderType: "Work order type",
  WorkOrderTypePrint: "Tip comandă de lucru",
  WorkOrderUpdated: "Work order updated successfully",
  DraftUpdatedSuccessfully: "Draft updated successfully",
  WorkOrderUuidExample: "Eg: DEM-1234567890-BR",
  WorkOverview: "Work overview",
  WorkOverviewIntro: "These are the work orders in the last 1 day",
  WorkstationSettings: "Workstation settings",
  Year: "Year",
  YearlyTotalOrders: "Yearly total orders",
  Yes: "Yes",
  YouCanBeginScanningTheCard: "You can begin scanning the card",
  YourAccountIsSuspended: "Your account is suspended",
  YourNextBillingDateIs: "Your next billing date is",
  SetupYourPin: "Setup your pin",
  SetupPinText:
    "Create a 4 digit pin to speed up your login, and remember to not share it with anyone!",
  UpdateYourPin: "Update your pin",
  UpdatePinText:
    "Use a memorable pin to speed up your login, and remember to not share it with anyone!",
  TooShort: "Too short",
  VeryWeak: "Very weak",
  Weak: "Weak",
  Medium: "Medium",
  Strong: "Strong",
  VeryStrong: "Very strong",
  PasswordMustMeetFollowingRequirements:
    "Password must meet the following requirements:",
  PleaseProvideAStrongPassword: "Please provide a strong password.",
  AtLeastOneLowercaseLetter: "At least one lowercase letter.",
  AtLeastOneCapitalLetter: "At least one capital letter.",
  AtLeastOneNumber: "At least one number.",
  AtLeastOneSpecialCharacter: "At least one special character.",
  AtLeast8CharactersLong: "At least 8 characters long.",
  PasswordsMustMatch: "Passwords must match.",
  Security: "Security",
  SetupPin: "Setup pin",
  UpdatePin: "Update pin",
  PaymentMethodsIntroText: "Manage your payment methods.",
  Working: "Working",
  GoneHome: "Gone home",
  Lunch: "Lunch",
  Break: "Break",
  OnHoliday: "On holiday",
  Away: "Away",
  Loading: "Loading",
  SelectStatus: "Select status",
  Guaranties: "Guaranties",
  GuarantiesIntroText: "Manage and track your guaranties",
  CreatedByUser: "Created by user",
  CreatedDate: "Created date",
  CreatedBy: "Created by",
  Details: "Details",
  ChooseAClient: "Choose a client",
  DraftCreated: "Draft created successfully",
  LiveTimesheet: "Live timesheet",
  LiveTimesheetIntroText: "Live tracking for the work statuses of your users.",
  DayShorten: "d",
  StartDate: "Start date",
  EndDate: "End date",
  CompanyInformation:
    "Companie <strong>{{name}}</strong> | C.I.F. / C.U.I <strong>{{vatNumber}}</strong> | Nr. de înregistrare <strong>{{regNumber}}</strong>",
  CompanyAddress: "Adresă <strong>{{fullAddress}}</strong>",
  CompanyContactInfo:
    " | Telefon <strong>{{phone}}</strong> | Email <strong>{{email}}</strong>",
  CompanySignature: "Company signature",
  CompanySignaturePrint: "Semnătura companiei",
  CompanyConsilierPrint: "Consilier service",
  ClientSignature: "Client signature",
  ClientSignaturePrint: "Semnătura clientului",
  SignaturePrint: "Semnătura",
  CertificateOfQualityAndGuarantee: "Certificate of quality and guarantee",
  CertificateOfQualityAndGuaranteePrint: "Certificat de calitate și garanție",
  QualityAndGuaranteeParagraphOne:
    "Unitatea service garantează lucrarea executată*) și conformitatea acesteia**), potrivit OUG140/2021 *3 luni de la dată recepției autovehiculului, dacă lucrarea nu anecesitat înlocuiri de piese sau dacă s-a executat cu piesă clientului.",
  QualityAndGuaranteeParagraphTwo:
    "** Garanția pieselor furnizate de unitate, în baza OUG140/2021 - conf. declarației producătorului/prin convenție între părți, condițional, de resp. indicațiilor deexploatare prevăzute de producătorul autovehiculului este de ___ luni.",
  QualityAndGuaranteeParagraphThree:
    "*** La cererea BENEFICIARULUI unitatea va asigura vizualizarea activităților desfășurate și înregistrări care să dovedească efectuarea inspecțiilor finale.",
  QualityAndGuaranteeParagraphFour:
    "**** Nu se acordă garanție pentru piese furnizate de client.",
  WorkOrderClientDisclaimerOne:
    "Clientul a asistat și certificat prin semnătura de mai sus strângerea roților pe autovehivul și are obligația de a verifica prezoanele după 50 de km.",
  WorkOrderCustomDisclaimerParagraphOne:
    "Nu ne asumăm răspunderea  pentru deteriorări ale anvelopei sau jantei care nu au fost observate la efectuarea schimbului (gâlme,bolfe,cipituri, uzura neuniformă,separări de material, etc)",
  WorkOrderCustomDisclaimerParagraphTwo:
    "Unitatea service garantează conform OUG140/202, garanție 10 zile pentru manoperă și pentru produsele furnizate de unitatea noastră, conform cu certificatele de garanție emise de furnizor.",
  OrderDetails: "Order details",
  OrderDetailsPrint: "Detalii de comandă",
  CarPlate: "Car plate",
  CarPlatePrint: "Plăcuța de înmatriculare a mașinii",
  CarManufacturer: "Car manufacturer",
  CarManufacturerPrint: "Marcă auto",
  CarKm: "Car km",
  CarKmPrint: "Mașină km",
  RequestedServices: "Requested services",
  RequestedServicesPrint: "Servicii solicitate",
  ExecutedServices: "Executed services",
  ExecutedServicesPrint: "Servicii executate",
  MeasurementUnit: "UM",
  SelectAUser: "Select a user",
  TimesheetReports: "Timesheet reports",
  TimesheetReportsIntroText: "Timesheet reports intro text.",
  SelectWorkOrderType: "Select work order type",
  DefaultWorkOrderTypeHelp:
    "Set the default work order type across the app, in forms, searches and filters.",
  ModelType: "Model type",
  CustomerRequests: "Customer requests",
  ProviderProposals: "Provider proposals",
  AddNewRequest: "Add new request",
  AddNewProposal: "Add new proposal",
  RequestsAndProposals: "Requests and proposals",
  AppointmentsTodayIntro: "Your appointments for today.",
  AppointmentsCalendarIntro: "Schedule new appointments using the calendar.",
  AppointmentGroup: "Appointment group",
  CarModel: "Car model",
  CarModelMake: "Car Model / Make",
  SelectOrganization: "Select organization",
  SelectGroup: "Select group",
  IncludeTime: "Include time",
  InvalidTime: "Invalid time",
  AppointmentsOverview: "Appointments overview",
  AppointmentsOverviewIntro: "These are the appointments in the last 1 day",
  AppointmentsCalendarTitle: "Appointments calendar",
  YouHaveNoAppointmentsForToday: "You have no appointments for today.",
  DeleteAppointmentWarning:
    "This will <strong>permanently</strong> delete the appointment.",
  SignIn: "Sign In",
  SignInIntroText: "Please fill your information below",
  DontHaveAnAccount: "Don't have an account yet?",
  SignUp: "Sign Up",
  ForgotPassword: "Forgot password",
  TermsAndConditions: "Terms & Conditions",
  PrivacyPolicy: "Privacy Policy",
  LegalDocuments: "Legal Documents",
  ViewPerGroups: "View per groups",
  IncludeGroupless: "Include groupless",
  Groupless: "Groupless",
  SuspendedWorkOrders: "Suspended work orders",
  SuspendedWorkOrdersIntro:
    "These are the work orders that are awaiting payment upon completion",
  PinUpdatedConfirmation: "Pin successfully updated!",
  PasswordChangedConfirmation: "Your password has been successfully updated!",
  GetWorkOrderErrorApi:
    "An error has occured while trying to get the workorder.",
  UpdateWorkOrderErrorApi:
    "An error has occured while trying to update the workorder.",
  Intake: "Client",
  Password: "Password",
  Name: "Name",
  NamePrint: "Nume",
  Price: "Price",
  PriceVAT: "Price (with VAT)",
  PricePrint: "Preț",
  PricePrintVAT: "Preț (cu TVA)",
  Car: "Car",
  CarAppointment: "Car",
  CarPrint: "Auto",
  Duration: "Duration",
  Time: "Time",
  Calendar: "Calendar",
  Reset: "Reset",
  Filters: "Filters",
  Username: "Username",
  Draft: "Draft",
  Organization: "Organization",
  Live: "Live",
  View: "View",
  Previous: "Previous",
  Next: "Next",
  Totalization: "Totalization",
  History: "History",
  Payments: "Payments",
  Product: "Product",
  DailyWorkOrders: "Daily work orders",
  DailyWorkOrdersIntro: "Work orders that are currently in progress",
  Handheld: "Handheld",
  WorkOrderPrint: "Deviz de lucru",
  YouHaveSwitchedAccountsSuccessfully:
    "You have switched accounts successfully",
  SelectAll: "Select all",
  CompanyReportPdf: "Company Report PDF",
  CompanyReportExcel: "Company Report Excel",
  AdvancedReportPdf: "Advanced Report PDF",
  AdvancedReportExcel: "Advanced Report Excel",
  DailyReportPdf: "Daily Report PDF",
  DailyReportExcel: "Daily Report Excel",
  MonthlyReportPdf: "Monthly Report PDF",
  MonthlyReportExcel: "Monthly Report Excel",
  WasteReportPdf: "Waste Report PDF",
  WasteReportExcel: "Waste Report Excel",
  WorkersReportPdf: "Workers Report PDF",
  WorkersReportExcel: "Workers Report Excel",

  WorkOrderReportPdf: "Work Order Report PDF",
  WorkOrderReportExcel: "Work Order Report Excel",
  GenerateWorkOrderReportPdf: "Generate Work Order Report PDF",
  GenerateWorkOrderReportExcel: "Generate Work Order Report Excel",
  GenerateWorkOrderReportSuccess:
    "Work Order Report PDF was successfully generated.",
  GenerateWorkOrderReportExcelSuccess:
    "Work Order Report Excel was successfully generated.",

  WorkOrdersProductReportPdf: "Work Orders Product Report PDF",
  WorkOrdersProductReportExcel: "Work Orders Product Report Excel",
  GenerateWorkOrdersProductReportPdf: "Generate Work Orders Product Report PDF",
  GenerateWorkOrdersProductReportExcel:
    "Generate Work Orders Product Report Excel",
  GenerateWorkOrdersProductReportSuccess:
    "Work Orders Product Report PDF was successfully generated.",
  GenerateWorkOrdersProductReportExcelSuccess:
    "Work Orders Product Report Excel was successfully generated.",

  GenerateCompanyReportPdf: "Generating company report PDF.",
  GenerateCompanyReportExcel: "Generating company report Excel.",
  GenerateAdvancedReportPdf: "Generating advanced report PDF.",
  GenerateAdvancedReportExcel: "Generating advanced report Excel.",
  GenerateDailyReportPdf: "Generating daily report PDF.",
  GenerateDailyReportExcel: "Generating daily report Excel.",
  GenerateMonthlyReportPdf: "Generating monthly report PDF.",
  GenerateMonthlyReportExcel: "Generating monthly report Excel.",
  GenerateWasteReportPdf: "Generating waste report PDF.",
  GenerateWasteReportExcel: "Generating waste report Excel.",
  GenerateWorkersReportPdf: "Generating workers report PDF.",
  GenerateWorkersReportExcel: "Generating workers report Excel.",
  GenerateCompanyReportSuccess:
    "Company Report PDF was successfully generated.",
  GenerateCompanyReportExcelSuccess:
    "Company Report Excel was successfully generated.",
  GenerateAdvancedReportSuccess:
    "Advanced Report PDF was successfully generated.",
  GenerateAdvancedReportExcelSuccess:
    "Advanced Report Excel was successfully generated.",
  GenerateDailyReportSuccess: "Daily Report PDF was successfully generated.",
  GenerateDailyReportExcelSuccess:
    "Daily Report Excel was successfully generated.",
  GenerateMonthlyReportSuccess:
    "Monthly Report PDF was successfully generated.",
  GenerateMonthlyReportExcelSuccess:
    "Monthly Report Excel was successfully generated.",
  GenerateWasteReportSuccess: "Waste Report PDF was successfully generated.",
  GenerateWasteReportExcelSuccess:
    "Waste Report Excel was successfully generated.",
  GenerateWorkersReportSuccess:
    "Workers Report PDF was successfully generated.",
  GenerateWorkersReportExcelSuccess:
    "Workers Report Excel was successfully generated.",
  GeneratePdfReportError:
    "There was an error while trying to generate the PDF report.",
  GenerateExcelReportError:
    "There was an error while trying to generate the Excel report.",
  TotalWorkOrders: "Total work orders",
  AlreadyHaveAnAccount: "Already have an account?",
  FullName: "Full Name",
  ValidationCode: "Validation Code",
  EmailWithCodeSent:
    "An e-mail with the verification code has been sent to you. Please check your inbox and paste the verification code into the validation code field above so you can move forward with the registration process.",
  RetypePassword: "Re-type password",
  AddData: "Add data",
  UserInfo: "User info",
  CompanyName: "Company name",
  VATNumber: "VAT number",
  REGNumber: "REG number",
  Country: "Country",
  City: "City",
  County: "County",
  Street: "Street",
  StreetNumber: "Street no.",
  Website: "Website",
  SuccessfullyRegistered: "Your account has been successfully registered!",
  EditAppointment: "Edit Appointment",
  TyreHotelFooterText:
    "<b>Atenție!!!</b> Pentru anvelopele depozitate la <b>{{organizationName}}</b> , răspundem <b>12</b> luni! După care automat intră la deșeu!",
  AppointmentDateRangeInfo: "Available hours: {{ start }} - {{ end }}",
  Transfer: "Transfer",
  TransferWorkOrder: "Transfer work order",
  TransferingWorkOrder: "Transfering work order",
  AddTyreDimensions: "Add tyre dimensions",
  SelectDateTime: "Select date and time",
  AddInBulk: "Add in bulk",
  Add: "Add",
  Services: "Services",
  AddProductOrServiceInBulk: "Add products and services",
  CustomizedWorkOrder: "Customized work order",
  CustomizedWorkOrderExplanation: "Add custom information to your work order.",
  DocumentCreator: "Document creator",
  FinalReviewer: "Final reviewer",
  WorkOrderDeadline: "Deadline",
  WorkOrderExpirationDate: "Expiration date",
  WorkOrderMetadata_createdBy: "Document creator",
  WorkOrderMetadata_finalReviewer: "Final reviewer",
  WorkOrderMetadata_deadline: "Deadline",
  WorkOrderMetadata_ownProduct: "Company client",
  WorkOrderMetadata_expirationDate: "Expiration date",
  WorkOrderMetadata_smsNotification: "SMS notification",
  WorkOrderPdfMetadata_deadline: "<strong>Deadline:</strong> {{deadline}}",
  WorkOrderMetadata_clientRequested: "At the strong request of the client",
  WorkOrderMetadata_clientBringsProducts: "Client brings products",
  WorkOrderMetadata_withVat: "With VAT",
  WorkOrderMetadata_vatValue: "VAT value",
  WorkOrderMetadata_vatPercentage: "VAT percentage",

  TyreHotelFooterTextCustom:
    "<p>Depozitarea anvelopelor se face pe o perioadă de 6 luni, iar clientul achită la ridicarea sau înlocuirea anvelopelor suma de:</p><ul><li> 75 de lei - pentru 4 anvelope fară jante </li><li> 95 de lei - pentru 4 anvelope cu jante </li></ul>",
  DateOfTyre: "DOT",
  PrintAll: "Print all",
  PrintAllTooltip: "Print work order + tyre hotel",
  SaveAndPrint: "Save and print",
  SMSLanguage: "SMS language",
  SelectSMSLanguage: "Select SMS language",
  CalendarView: "Calendar view",
  ThisWeek: "This week",
  PlateNumberSearchResults: "Plate number search results",
  PlateNumberSearchResultsText:
    "The following results were found for this plate number. Please select which data you want to use.",
  PrefillData: "Prefill data",
  Selected: "Selected",
  WithRim: "With rim",
  CustomerBringsProducts: "Client of Pangus",
  TyreCount: "Count",
  CustomerOrder: "Customer order",
  CustomerOrderIntroText: "Track and manage the order of customers.",
  Overview: "Overview",
  CustomerOrderOverviewText: "These are the customer orders for today.",
  ViewPerGroup: "View per group",
  AddNewCustomerOrder: "Add new customer order",
  CarLabel: "Car",
  CreatedByUsername: "Created by {{user}}",
  AccountDeletedStatus: "Your account is currently being deleted.",
  AccountDeletedInfo:
    "Once done you will be logged out and all your data will be deleted.",
  SMSNotification: "SMS notification",
  ITPExpirationDatePrint: "Data expirării",
  WorkOrderITPPrint: "Deviz de lucru - ITP",
  SmsReminderSent: "SMS reminder sent",
  ReSendSMSNotification: "Resend SMS",
  Workstation: "Workstation",
  RequestedByClient: "At the strong request of the client",
  RequestedByClientDisclaimer:
    "I acknowledge that I have been informed of the recommended repair options, but I insist on proceeding with the requested repair method.",
  TechnicalDifficultiesError:
    "Oops! We're experiencing some technical difficulties at the moment. Please try again later or contact our support team for assistance.",
  PrintWorkOrderMetadata_createdBy: "Document întocmit de",
  PrintWorkOrderMetadata_finalReviewer: "Inspecția finală",
  PrintWorkOrderPdfMetadata_deadline:
    "<strong>Termenul de execuție:</strong> {{deadline}}",
  RequestedByClientDisclaimerPrint:
    "Recunosc că am fost informat despre opțiunile recomandate de reparație, dar insist să procedez cu metoda de reparație solicitată.",
  PlateNumberPrint: "Nr. de înmatriculare",
  YearPrint: "Anul",
  VinNumberPrint: "Serie șasiu / Număr VIN",
  ClosedAtPrint: "Închis la",
  StatusPrint: "Status",
  ClosedPrint: "Închis",
  CarModelPrint: "Model",
  DownloadPDF: "Download PDF",
  DownloadExcel: "Download Excel",
  Axis2Tyres4: "2 Axis 4 Tyres",
  Axis2Tyres6: "2 Axis 6 Tyres",
  Axis3Tyres10: "3 Axis 10 Tyres",
  Axis4Tyres12: "4 Axis 12 Tyres",
  Axis5Tyres12: "5 Axis 12 Tyres",
  AxisAndTyres: "{{axis}} Axis {{tyres}} Tyres",
  SignUpIntroText1: "Join our community and unlock a world of possibilities.",
  SignUpIntroText2:
    "To get started, we need a few from you. Please provide your full name, email address, and phone number.",
  SignUpIntroText3:
    "Once you've filled in the required information, we'll send you a verification code to your email address. Simply enter the code to verify your email, and you'll be ready to explore all the amazing features and benefits our web application has to offer.",
  TimeSheet: "Timesheet",
  Validation: "Validation",
  Validate: "Validate",
  Finish: "Finish",
  AccessDenied403:
    "Access Denied! Unfortunately, you are not authorized to do this action or access this resource. Please get in touch with your user administrator for assistance.",
  SingleTotal: "Single",
  CommonTotal: "Common",
  Tyre: "Tyre",
  StepCount: "Step {{step}}",
  TyreMake: "Tyre make",
  TyreModel: "Tyre model",
  DiscountOnNewTyres: "Discount on new tyre",
  ColorsLegend: "Colors legend",
  WithServices: "With services",
  CloseCustomerOrder: "Close customer order",
  Worker: "Worker",
  WorkOrdersExcelExport: "Work orders excel export",
  ExportError: "There was an error while trying to export the data",
  ExportOfDataInProgress: "Export of data in progress",
  WorkOrdersExportSuccess: "Work orders have been successfully exported",
  From: "From",
  To: "To",
  User: "User",
  AppointmentsReportPdf: "Appointments Report PDF",
  AppointmentsReportExcel: "Appointments Report Excel",
  GenerateAppointmentsReportPdf: "Generating appointments report PDF.",
  GenerateAppointmentsReportExcel: "Generating appointments report Excel.",
  GenerateAppointmentsReportSuccess:
    "Appointments Report PDF was successfully generated.",
  GenerateAppointmentsReportExcelSuccess:
    "Appointments Report Excel was successfully generated.",
  SMSReport: "SMS Report",
  SMSReportPdf: "SMS Report PDF",
  SMSReportExcel: "SMS Report Excel",
  GenerateSMSReportPdf: "Generating SMS report PDF.",
  GenerateSMSReportExcel: "Generating SMS report Excel.",
  GenerateSMSReportSuccess: "SMS Report PDF was successfully generated.",
  GenerateSMSReportExcelSuccess: "SMS Report Excel was successfully generated.",
  TotalSMS: "Total SMS",
  TotalAppointments: "Total appointments",
  ClientBringsProducts: "Products brought by client",
  ProductsBroughtByClientPrint: "Produse aduse de client",
  Service: "Service",
  ServiceName: "Service name",

  /**
   * Account settings translations
   */
  withVat: "With VAT",
  vatPercentage: "VAT percentage",
  productProfitPercent: "Product profit percent",
  smsLanguage: "SMS Language",
  ownProductDiscount: "Own product discount",
  itpEnabled: "ITP enabled",
  hideCreateNewTyreHotel: "Hide create new tyre hotel",
  customLogo: "Custom logo",
  appointmentsStart: "Appointments start time",
  appointmentsEnd: "Appointments end time",
  appointmentsRequired: "Appointment required fields",
  discountOnNewProduct: "Discount on new product",
  customized: "Customized",
  woRemoveDiscount: "Remove discount from work order",
  woPdfPriceLabelWithVAT: "Price label with VAT",
  woPdfTyreTorqueNewLine: "Tyre torque new line",
  thWarehouseCustom: "Warehouse custom",
  woPdfCompanySignatureLabelRename: "Company signature label rename",
  woPdfClientSignatureUnderExecutedServices:
    "Client signature under executed services",
  woPdfBottomText: "Disclaimer text in PDF footer",
  hasWoPdfConfig: "Has work order PDF config",
  woPdfBottomTextTitle: "Disclaimer text in PDF footer title",
  woCleanRequestedServices: "Clean requested services",
  woPdfHideProductsColumns: "Hide products columns",
  woAddProductsV2: "Add products V2",
  YesPrint: "Da",
  NoPrint: "Nu",
  AppointmentsStartTime: "Appointments start time",
  AppointmentsEndTime: "Appointments end time",
  AppointmentsStartTimeExample: "Eg: 08:00",
  AppointmentsEndTimeExample: "Eg: 18:00",
  WithGroup: "With group",
  Slug: "Slug",
  CreatedGuestAppointmentSuccess:
    "Mulțumim pentru programarea unei întâlniri cu noi! Vom lua legătura în curând pentru a confirma programarea dumneavoastră.",
  ConfirmAppointment: "Confirm appointment",
  PublicAppointments: "Public appointments",
  Contacts: "Contacts",
  EnablePublicCalendar: "Enable public calendar",
  Address: "Address",
  NoResultsFound: "No results found",
  Back: "Back",
  SuspendedWorkOrdersShort: "Suspended",
  ReportPdf: "Report PDF",
  GenerateReportPdf: "Generating report PDF.",
  GenerateReportSuccess: "Report PDF was successfully generated.",
  ReportExcel: "Report Excel",
  GenerateReportExcel: "Generating report Excel.",
  GenerateReportExcelSuccess: "Report Excel was successfully generated.",
  ExcludeDates: "Days excluded from the public calendar",
  ExcludeSaturdays: "Exclude Saturdays",
  Preview: "Preview",
  Close: "Close",
  GoToWorkOrder: "Go to work order",
  SMSMessages: "SMS messages",
  SMSMessagesIntroText: "Customize your SMS messages.",
  Message: "Message",
  MessageType: "Message type",
  SMSMessageExplanation:
    '<p>You can create your own SMS messages by using the following variables to replace specific information:</p> <ul> <li>$organizationName: This will be replaced by the name of the organization.</li> <li>$from: This will be replaced by the date of the appointment.</li> <li>$address: This will be replaced by the address of the organization.</li> </ul> <p>Example message: "You have an appointment at $organizationName on $from, address: $address".</p>',
  SMSMessageExplanationWithITP:
    '<p>You can create your own SMS messages by using the following variables to replace specific information:</p> <ul> <li>$organizationName: This will be replaced by the name of the organization.</li> <li>$from: This will be replaced by the date of the appointment.</li> <li>$address: This will be replaced by the address of the organization.</li> <li>$plateNumber: This will be replaced with the car\'s license plate number.</li> </ul> <p>Example message: "You have an appointment at $organizationName on $from, address: $address".</p>',
  SMSMessageAppointmentCreated: "Appointment created",
  SMSMessageAppointmentNotification: "Appointment notification",
  SMSMessageAppointmentDeleted: "Appointment deleted",
  SMSMessageITPNotification: "ITP notification",
  Characters: "Characters",
  CreditsRequired: "{{credits}} Credits required.",
  CreditRequired: "1 Credit required.",
  CreditsExample: "2 Credits.",
  Credit: "Credit.",
  WorkOrderProducts: "Work order products",
  WorkOrderProductsReport: "Work order products report",
  TotalUM: "Total buc",
  isService: "Service",
  isTyre: "Tyre",
  PageNotFound: "Page not found",
  SuccessMessage: "Success message",
  AppointmentsBreakInterval: "Break interval",
  AppointmentsBreakIntervalExample: "Eg: 13:00 - 14:00",
  Available: "Available",
  Reserved: "Reserved",
  Unavailable: "Unavailable",
  RecalculateDiscount: "Recalculate discount",
  NewGuestAppointmentsDoubleClick:
    "For new appointments, please double click on the available slot.",
  SimpleService: "Simple service",
  PleaseConfirmForDeletion: "Please confirm for deletion",
  Collaborator: "Collaborator",
  Collaborators: "Collaborators",
  WorkOrderCollaborator: "Work order - collaborator",
  CollaboratorWorkOrders: "Work orders - collaborators",
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  ChooseACollaborator: "Choose a collaborator",
  CollaboratorName: "Collaborator name",
  IUnderstandDeleteCollaborator: "I understand, delete collaborator",
  DeleteCollaboratorWarning:
    "This will <strong>permanently</strong> delete the collaborator.",
  Form: "Form",
  Code: "Code",
  Return: "Return",
  Paid: "Paid",
  ModifiedBy: "Modified by",
  ViewMode: "View",
  Operators: "Operators",
  Operator: "Operator",
  CarYear: "Year",
  Make: "Make",
  WorkOrderReport: "Work order report",

  /**
   * Socket event translations
   */
  WORK_ORDER_CREATED: "Work order created",
  WORK_ORDER_UPDATED: "Work order updated",
  WORK_ORDER_DELETED: "Work order deleted",
  LOYALTY_CARD_CREATED: "Loyalty card created",
  LOYALTY_CARD_UPDATED: "Loyalty card updated",
  LOYALTY_CARD_DELETED: "Loyalty card deleted",
  CLIENT_CREATED: "New client created",
  CLIENT_UPDATED: "Client updated",
  CLIENT_DELETED: "Client deleted",
  PRODUCT_CREATED: "New product created",
  PRODUCT_UPDATED: "Product updated",
  PRODUCT_DELETED: "Product deleted",
  TYREHOTEL_CREATED: "New tyre hotel created",
  TYREHOTEL_UPDATED: "Tyre hotel updated",
  TYREHOTEL_DELETED: "Tyre hotel deleted",
  APPOINTMENTS_CREATED: "New appointment created",
  APPOINTMENTS_UPDATED: "Appointment updated",
  APPOINTMENTS_DELETED: "Appointment deleted",

  /**
   * Dynamic translations
   */
  "[fieldValue] not equal with [hasToMatch]":
    "{{fieldValue}} not equal with {{hasToMatch}}",
  "At least [min]": "At least {{min}} characters.",
  "At least [minLength]": "At least {{minLength}} characters.",
  "At most [max]": "At least {{max}} characters.",
  "At most [maxLength]": "At most {{maxLength}} characters.",
  "No support for your screen size [width]x[height]":
    "At this time we do not provide support for your screen size (<strong>{{width}}</strong>x<strong>{{height}}</strong>).",
  "Please type [keyWord] to confirm":
    "Please type <strong>{{keyWord}}</strong> to confirm.",
  "This work order was created [timeElapsed]":
    "This work order was created {{timeElapsed}}.",
  "This work order was updated [timeElapsed]":
    "This work order was updated {{timeElapsed}}.",
  "Member since [time]": "Member since <strong>{{time}}<strong/>.",
  "Account level: [level]": "Account level: <strong>{{level}}<strong />.",
  "Username: [username]": "Username: <strong>{{username}}<strong />.",
  "Your next billing is due: [dueDate]":
    "Your next billing is due: <strong>{{dueDate}}<strong />.",
  "Hello [user], your account is currently suspended":
    "Hello <strong>{{user}}</strong>, your account is currently suspended.",

  /**
   * Filter fields
   */
  "filter.field_account_id": "Account",
  "filter.field_name": "Name",
  "filter.field_email": "Email",
  "filter.field_first_name": "First name",
  "filter.field_last_name": "Last name",
  "filter.field_timesheet_status": "Status",
  "filter.field_total": "Total",
  "filter.field_uuid": "Work order",
  "filter.field_discount": "Discount",
  "filter.field_products": "Products",
  "filter.field_quick_text": "Search",
  "filter.field_subtotal": "Subtotal",
  "filter.field_username": "Username",
  "filter.field_type": "Account type",
  "filter.field_tyre_rim": "Tyre rim",
  "filter.field_finished": "Finalized",
  "filter.field_is_service": "Service",
  "filter.field_car.model": "Car model",
  "filter.field_company.email": "Email",
  "filter.field_tyre_name": "Tyre name",
  "filter.field_car_type_id": "Car type",
  "filter.field_fidelity": "Loyal client",
  "filter.field_vin_number": "Vin number",
  "filter.field_tyre_width": "Tyre width",
  "filter.field_totalization": "Totalized",
  "filter.field_client_name": "Client name",
  "filter.field_created_at": "Created date",
  "filter.field_is_company": "Legal entity",
  "filter.field_car_number": "Plate number",
  "filter.field_tyre_height": "Tyre height",
  "filter.field_card_number": "Card number",
  "filter.field_is_expendable": "Expendable",
  "filter.field_plate_number": "Plate number",
  "filter.field_delegate_name": "Delegate name",
  "filter.field_discount_type": "Discount type",
  "filter.field_organization_id": "Organization",
  "filter.field_payment_type_id": "Payment type",
  "filter.field_discount_value": "Discount value",
  "filter.field_car_plate_number": "Plate number",
  "filter.field_company.vat_number": "VAT number",
  "filter.field_tyreService.tyre_rim": "Tyre rim",
  "filter.field_is_count_price": "Price activated",
  "filter.field_car_manufacturer": "Car manufacturer",
  "filter.field_car.make": "Car manufacturer",
  "filter.field_tyreService.tyre_width": "Tyre width",
  "filter.field_work_order_type_id": "Work order type",
  "filter.field_tyreService.tyre_height": "Tyre height",
  "filter.field_company.reg_number": "Registration number",
  "filter.field_created_by": "Created by",
  "filter.field_status": "Status",
  "filter.field_user_id": "User",
  "filter.field_start_date": "Start date",
  "filter.field_end_date": "End date",
  "filter.field_appointment_groups": "Appointment groups",
  "filter.field_itp": "ITP",
  "filter.field_car_make": "Car manufacturer",
  "filter.field_car_model": "Car model",
  "filter.field_is_tyre": "Tyre",
  "filter.field_discount_on_new_tyre": "Discount on new tyre",
  "filter.field_workers": "Worker",
  "filter.field_from": "Interval",
  "filter.field_services": "Services",
  "filter.field_function": "Function",
  "filter.field_collaborator_name": "Collaborator name",
  "filter.field_phone": "Phone",
  "filter.field_payment_type": "Payment type",
};
