import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  type AppDispatch,
  publicAppointmentsActions,
  changePublicAppointmentsDate,
} from "../..";

export const usePublicAppointmentsActions = () => {
  const dispatch: AppDispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators(
      {
        ...publicAppointmentsActions,
        changePublicAppointmentsDate,
      },
      dispatch,
    );
  }, [dispatch]);
};
