import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { toHashMap } from "../../utils";
import { searchCollaborators } from "../../features";

import { ReduxSlice } from "../../features";
import type { Collaborator } from "../../../types";
import type { CollaboratorsState } from "./collaborators.types";
import type { SocketEventPayload } from "../../services";

/**
 * Defines the clients initial state
 */
const initialState: CollaboratorsState = {
  loading: false,
  collaborators: [],
  collaboratorsHashMap: {},
};

/**
 * Handles creating the a redux state slice
 */
export const collaboratorsSlice = createSlice({
  name: ReduxSlice.Clients,
  initialState,
  reducers: {
    updateCollaborators: (state, action: PayloadAction<Collaborator[]>) => {
      state.collaborators = action.payload;
    },
    updateCollaboratorsHashMap: (
      state,
      action: PayloadAction<Collaborator[]>,
    ) => {
      state.collaboratorsHashMap = toHashMap(action.payload, "id");
    },
    resetCollaboratorsState: (state) => {
      state.collaborators = [];
      state.collaboratorsHashMap = {};
    },
    _updateCollaboratorsLive: (
      state,
      action: PayloadAction<SocketEventPayload<Collaborator>>,
    ) => {
      const { event, model } = action.payload;

      if (event === "CREATED") {
        state.collaborators = [...state.collaborators, model];
      }

      if (event === "UPDATED") {
        state.collaborators = state.collaborators.map((item) =>
          item.id === model.id ? { ...model } : item,
        );
      }

      if (event === "DELETED") {
        state.collaborators = state.collaborators.filter(
          (item) => item.id !== model.id,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(searchCollaborators.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      searchCollaborators.matchFulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.collaborators = payload.data.items;
        state.collaboratorsHashMap = toHashMap(payload.data.items, "id");
      },
    );
  },
});

/**
 * Exports the action-creators
 */
export const collaboratorsActionCreators = collaboratorsSlice.actions;

/**
 * Exports the reducer
 */
export const collaboratorsReducer = collaboratorsSlice.reducer;
